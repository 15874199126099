<script setup lang="ts">
import {computed, inject, watch} from "vue";
import {storeToRefs} from "pinia";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {
  GLOBAL_TAB_SCHEDULING_PLANNING_CAPACITY,
  GLOBAL_TABS_QUERY_PARAMETER,
  SIMULATION_STATUS,
  GLOBAL_TAB_ANALYTICS_CROSSING_TIMES,
} from "@/config/constants";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "@/stores/mainStore";
import {SCHEDULING_ROUTES_NAMES_MAPPING} from "@/config/routes";
import {Segment} from "@/interfaces";

const mainStore = useMainStore();
const {isSchedulingFullScreen, isScheduling, mergedParameters, userData} =
  storeToRefs(mainStore);
const schedulingStore = useSchedulingStore();
const {hasLocalChanges, isSavingSchedulingChanges, selectedSimulation} =
  storeToRefs(schedulingStore);
const {t} = useI18n();

const route = useRoute();
const {
  globallySelectedTab,
  globalTabsPerRoute,
  isCurrentGlobalTab,
  selectedTab,
} = useGlobalTabs();

const segment = inject<Segment>("segment");

const isPilotingRoute = computed(() =>
  (route.name as string)?.includes(SCHEDULING_ROUTES_NAMES_MAPPING.piloting),
);

// Some route names are not complete (eg. all the parameters subpages, so we handle shorthands too)
const routeName = computed(() =>
  Object.keys(globalTabsPerRoute.value).find((shortName) => {
    const isMatchingRoute = (route.name as string)?.includes(shortName);

    return isPilotingRoute.value
      ? isMatchingRoute &&
          (selectedSimulation.value?.status === SIMULATION_STATUS.ARCHIVED ||
            mergedParameters.value?.unique_scheduling_simulation)
      : isMatchingRoute;
  }),
);

const tabColor = computed(() =>
  isPilotingRoute.value ? "newOrangeRegular" : "newPrimaryRegular",
);

const routeHasTabs = computed(
  () => routeName.value in globalTabsPerRoute.value,
);

const displayAppTabs = computed(() => {
  // hiding tabs if scheduling & full screen
  const schedulingConditions =
    !isScheduling.value || !isSchedulingFullScreen.value;

  return routeHasTabs.value && schedulingConditions;
});

const showAppTabs = computed(
  () => globalTabsPerRoute.value?.[routeName.value]?.length > 1,
);

const tabs = computed(() => {
  return Array.from(
    globalTabsPerRoute.value?.[routeName.value] || [],
    (variable: string): string => t(`GlobalTabs.${variable}`),
  );
});

/**
 * initializes `selectedTab` once route is properly setup
 */
watch(
  route,
  () => {
    const {query} = route;
    const globalTabValue = +query[GLOBAL_TABS_QUERY_PARAMETER];
    if (!Number.isNaN(globalTabValue)) selectedTab.value = globalTabValue;
  },
  {once: true},
);

watch(globallySelectedTab, (_, oldGtab) => {
  // exit if it's startup change
  if (oldGtab === null) return;

  const {name, id, email, client_id, client_name} = userData.value;
  const trackMapping = {
    [GLOBAL_TAB_SCHEDULING_PLANNING_CAPACITY]: "[Ordo] Capacity Tab Clicked",
    [GLOBAL_TAB_ANALYTICS_CROSSING_TIMES]:
      "[Analytics] Crossing Times Tab Clicked",
  };

  for (const [trackedTab, eventTitle] of Object.entries(trackMapping)) {
    if (isCurrentGlobalTab(trackedTab)) {
      segment.value.track(eventTitle, {
        user: {name, id, email},
        client: {name: client_name, id: client_id},
      });
    }
  }
});
</script>

<template>
  <div class="app-tabs" v-if="displayAppTabs" v-show="showAppTabs">
    <v-tabs
      :key="JSON.stringify(tabs)"
      v-model="globallySelectedTab"
      class="tabs-bloc"
      horizontal
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab"
        :color="tabColor"
        class="tab-ordo tab-ordo-planning"
        :data-testid="tab"
        density="comfortable"
      >
        {{ tab }}
      </v-tab>
    </v-tabs>
    <div
      class="loading-spinner"
      v-if="isSavingSchedulingChanges || hasLocalChanges"
    >
      <v-progress-circular
        size="20"
        color="newPrimaryDark1"
        indeterminate
        class="ml-2"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/scss/constants";

.app-tabs {
  width: calc(100% - $leftSideBarMiniWidth);
  display: flex;
  align-items: center;
  gap: 16px;
  background: rgb(var(--v-theme-newLayerBackground));
  margin-left: 54px;
  padding: 0 var(--g-horizontal-spacing);
  margin-left: 54px;
  position: relative;

  &::before {
    content: "";
    height: 32px;
    border-left: 1px solid rgb(var(--v-theme-newSelected));
  }

  & .tab-ordo {
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    color: rgb(var(--v-theme-newDisableText));

    &:not(.v-tab--selected) {
      opacity: 0.6;
    }
  }
}
.loading-spinner {
  position: absolute;
  left: -15px;
}
</style>
