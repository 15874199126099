<template>
  <div
    :class="{'cursor-pointer': canToggleOperations}"
    :key="mc.secteur_id"
    class="sheduling-row-sector pa-0"
    @click="onHeaderClick"
  >
    <CapacityDayCell
      :mc="mc"
      :period-data="periodData"
      :selected-view="selectedView"
      :suffix="getDayCellSuffix(pg_ops)"
      show-prefix
      is-full-week
    />

    <v-tooltip location="top" v-if="isDevEnv">
      <template v-slot:activator="{props}">
        <div
          v-bind="props"
          class="refresh-btn cursor-pointer"
          icon
          @click.stop="refreshAll"
        >
          <v-progress-circular
            v-if="spinner.charge"
            size="20"
            color="newPrimaryDark1"
            indeterminate
            class="mr-2"
          />
          <vue-feather tag="div" type="refresh-ccw" size="14" />
        </div>
      </template>
      <div>{{ $t("Planning.update_data") }}</div>
    </v-tooltip>

    <div
      class="scheduling-row-sector-name text-h5"
      data-testid="scheduling-row-sector-name"
    >
      <div class="d-flex align-center justify-space-between">
        <div
          class="d-flex align-center gap-4"
          :data-testid="`scheduling-row-header-${mc.secteur_id}`"
        >
          <vue-feather v-if="mc.is_machine" type="tool" />

          <v-tooltip location="top">
            <template v-slot:activator="{props}">
              <strong v-bind="props">
                {{ computedSectorName }}
              </strong>
            </template>
            <strong>{{ computedSectorName }}</strong>
          </v-tooltip>
        </div>

        <vue-feather
          v-if="canToggleOperations"
          :type="areToggledOperations ? 'chevron-up' : 'chevron-down'"
          class="cursor-pointer"
          :data-testid="`toggle-operations-${mc.secteur_id}-${
            areToggledOperations ? 'up' : 'down'
          }`"
        />
      </div>
      <DevHelper v-if="isDevEnv" class="fcaption">
        ({{ mc.secteur_id }})
      </DevHelper>
      <v-progress-circular
        v-if="showPropsSpinner"
        size="20"
        color="newPrimaryDark1"
        indeterminate
        class="ml-2"
      />

      <v-tooltip
        v-if="isArchivedSimulation || viewType === 'piloting'"
        location="top"
      >
        <template v-slot:activator="{props}">
          <div v-bind="props" class="d-flex flex-column gap-4 mt-4">
            <div class="text-body-2">
              <span>
                {{ $t("Home.tx_avancement") }}
              </span>
              <v-progress-circular
                v-if="spinner.prod"
                size="20"
                color="newPrimaryDark1"
                indeterminate
                class="ml-2"
              />
            </div>
            <div class="capa-percent-wraper">
              <span
                :class="`capa-percent ${computeDoneQuantityWidthDiv}`"
                :style="{
                  width: `${computeDoneQuantityWidth}%`,
                }"
              />
              <span
                class="capa-percent-bar"
                :style="{left: `${dayInWeekProgess}%`}"
              />
            </div>
            <div class="d-flex justify-space-between w-100">
              <span class="fbody-2 font-weight-bold">
                {{ computeDoneQuantityWidth }} %
              </span>
              <span class="fbody-2 font-italic">
                {{ computeCurrentPeriodText }}
              </span>
            </div>
          </div>
        </template>
        <span class="text-body-2">
          {{ $t("Home.tx_avancement") }} : {{ computeDoneQuantityText }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, PropType} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import {CapacityDayCell} from "@/components/Scheduling/Capacity";
import moment from "moment";
import {
  asyncForEach,
  buildRecursivelySectorTree,
  levelCorresp,
  workDaysSum,
  getDisplayedPeriod,
  getOFUnit,
  getOperationStatus,
  SchedulingOperation,
} from "@oplit/shared-module";
import {SectorLike} from "@/interfaces";
import {OF_STATUS} from "@/config/constants";
import {useMainStore} from "@/stores/mainStore";
import {useIsArchivedSimulation} from "@/composables/useIsArchivedSimulation";
import {useSchedulingStore} from "@/stores/schedulingStore";

export default defineComponent({
  name: "scheduling-row-header",
  components: {CapacityDayCell},
  props: {
    mc: {type: Object as PropType<SectorLike>, default: (): SectorLike => ({})},
    pg_updateCharge: {type: Function, default: () => void 0},
    pg_ops: {type: Array as PropType<SchedulingOperation[]>}, //don't set default to this props, or set to undefined
    periodData: {type: Object, default: () => ({})},
    showPropsSpinner: {type: Boolean, default: false},
    viewType: {type: String, required: true},
    // whether or not all operations are visible
    areToggledOperations: {type: Boolean, default: false},
    // whether or not we can handle the display of all operations through this component
    canToggleOperations: {type: Boolean, default: false},
    selectedView: {
      type: String as PropType<"day_view" | "week_view" | "month_view">,
    },
  },
  emits: ["toggle-operations"],
  setup(props, {emit}) {
    const mainStore = useMainStore();
    const {
      perimeters,
      pgRefresh,
      isDevEnv,
      userData,
      calendars,
      clientParameters,
      stations,
    } = storeToRefs(mainStore);
    const {isArchivedSimulation} = useIsArchivedSimulation();
    const {getDayCellSuffix} = useSchedulingStore();

    function onHeaderClick() {
      if (!props.canToggleOperations) return;
      emit("toggle-operations");
    }

    return {
      produced: ref<number>(0),
      qte: ref<number>(0),
      spinner: ref<Record<string, boolean>>({prod: false, charge: false}),
      perimeters,
      pgRefresh,
      isDevEnv,
      userData,
      calendars,
      clientParameters,
      stations,
      isArchivedSimulation,
      onHeaderClick,
      getDayCellSuffix,
    };
  },
  computed: {
    parsedPeriod() {
      return this.periodData;
    },
    sectorTree() {
      const {mc, perimeters} = this;
      const {secteur_id = "operations", secteur_collection, is_machine} = mc;
      if (is_machine) return {...mc, ...levelCorresp[0]};
      const match = (perimeters[secteur_collection] || []).find(
        (op: any) => op.id === secteur_id,
      );
      return buildRecursivelySectorTree(match, perimeters);
    },
    dayInWeekProgess() {
      const {parsedPeriod, calendars, sectorTree} = this;
      const {startDate, endDate} = parsedPeriod;
      if (!startDate || !endDate) return "";
      const today = moment().subtract(1, "days").format("YYYY-MM-DD");

      if (moment().startOf("week").format("YYYY-MM-DD") > endDate) return 100;
      else if (moment().endOf("week").format("YYYY-MM-DD") < startDate)
        return 0;

      const monthCalendars = calendars.filter(
        (x: any) =>
          x.date >= startDate &&
          x.date <= endDate &&
          (x.secteur_id === sectorTree?.id ||
            levelCorresp.some(
              (level: any) => x.secteur_id === sectorTree[level.type + "_id"],
            )),
      );
      //Now we need to filter out in the other direction : if a parent was modified prior to the child, it should be ignored
      const orderedCalendars = monthCalendars.filter(
        (x: any, idx: number, arr: any) => {
          const restArr = arr.slice(idx + 1);
          if (
            restArr.some(
              (c: any) => c.date === x.date && c.updated_at >= x.updated_at,
            )
          )
            return false;
          return true;
        },
      );
      const tx_avancement = workDaysSum(startDate, today, orderedCalendars);
      const total_cap = workDaysSum(startDate, endDate, orderedCalendars);
      return (total_cap ? tx_avancement / total_cap : 0) * 100;
    },
    computeDoneQuantityText() {
      const {produced, qte, pg_ops} = this;
      return `${_.round(produced)} / ${_.round(qte || 0)} ${getOFUnit(
        pg_ops?.[0],
      )}`;
    },
    computeDoneQuantityWidth() {
      const {produced, qte} = this;
      return _.clamp(_.round((produced / (qte || 1)) * 100, 2), 0, 100);
    },
    computeDoneQuantityWidthDiv() {
      return this.computeDoneQuantityWidth >= this.dayInWeekProgess
        ? "capa-percent-full"
        : "capa-percent-less";
    },
    computedSectorName() {
      const {stations, mc: sector, clientParameters} = this;
      const {secteur_id} = sector;
      if (!clientParameters.display_parent_text) return sector.secteur_name;
      const match = stations.find((x: any) => x.id === secteur_id);
      if (!match) return sector.secteur_name;
      const {parent_text} = match;
      return parent_text || sector.secteur_name;
    },
    computeCurrentPeriodText(): string {
      const {
        periodData: {startDate, maille, periodArray},
      } = this;

      const date =
        maille === "week"
          ? startDate
          : periodArray.at(1)?.start_date || periodArray.at(0)?.end_date;
      if (!date) return "";
      return `(${getDisplayedPeriod(
        date,
        maille === "month",
        this.$i18n.locale,
      )})`;
    },
  },
  watch: {
    pg_ops: {
      immediate: true,
      handler() {
        this.loadAllProd();
      },
      deep: true,
    },
    async parsedPeriod() {
      this.spinner.prod = true;
      await this.loadAllProd();
      this.spinner.prod = false;
    },
    pgRefresh(val: number) {
      if (!val) return;
      this.spinner.charge = false;
    },
  },
  created() {
    this.loadAllProd = _.debounce(this.loadAllProd, 300);
  },
  methods: {
    async loadAllProd() {
      const {pg_ops, isArchivedSimulation, viewType} = this;

      if (!isArchivedSimulation && viewType !== "piloting") return;
      if (!pg_ops?.length) {
        this.produced = 0;
        this.qte = 0;
        return;
      }
      const promises = [];
      await asyncForEach(pg_ops, async (x: any) => {
        promises.push(this.computeProduced(x));
      });
      const results = await Promise.all(promises);
      const produced = _.sumBy(results, (o: number) => +o || 0);
      const qte = _.sumBy(pg_ops, (x: any) => +x.quantite_of);
      this.produced = produced;
      this.qte = qte;
    },
    async computeProduced(operation: any) {
      if (!operation?.op_id) return;
      const {quantity_produced = 0, quantite_of = 0} = operation;
      if (getOperationStatus(operation) === OF_STATUS.DONE) return +quantite_of;

      return Math.min(+quantity_produced, quantite_of);
    },
    refreshAll() {
      this.spinner.charge = true;
      this.pg_updateCharge();
    },
  },
});
</script>
<style scoped lang="scss">
.sheduling-row-sector {
  border: 1px solid rgb(var(--v-theme-newSelected));
}

.calendar-row-content {
  & .refresh-btn {
    position: sticky;
    top: 55px;
    margin-top: -18px;
    display: flex;
    padding-right: 12px;
    justify-content: flex-end;
    z-index: 1;
  }

  &:first-child .refresh-btn {
    margin-top: -25px;
  }
}
</style>
<style lang="scss">
@import "@/scss/constants";

.capa-percent {
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 168px 0 0 168px;
  &-full {
    background-color: rgb(var(--v-theme-newGreenRegular));
    border-radius: 168px;
  }
  &-less {
    background-color: rgb(var(--v-theme-newPinkRegular));
    border-radius: 168px 0 0 168px;
  }
  &-wraper {
    width: 100%;
    height: 12px;
    background-color: rgb(var(--v-theme-newDisableBG));
    border-radius: 168px;
    position: relative;
  }
  &-bar {
    position: absolute;
    background-color: rgb(var(--v-theme-newMainText));
    height: 24px;
    width: 2px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.piloting-calendar-scheduling-row {
  border: 1px solid rgb(var(--v-theme-newSelected));
}

.scheduling-row-sector-name {
  position: sticky;
  /*
   * 22px is the height of the CapacityDayCell above
   * its value comes from the .fbody-2 line-height (21px) + the vertical borders (2px) applied to it
   */
  top: calc($schedulingCalendarHeaderCellHeight + 23px);
  padding: 16px;
  word-break: break-word;

  & i {
    flex: 0 0 24px;
  }
}
</style>
