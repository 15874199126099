<template data-cy="dropdown-menu">
  <v-menu
    v-model="isOpen"
    ref="dropdown-menu"
    :z-index="zIndex"
    :content-class="computedContentClass"
    :transition="transition"
    :close-on-content-click="!keepOpen"
    :max-width="width"
    :min-width="width"
    :max-height="height"
    :offset="offset"
    :disabled="disabled"
    :location="top ? 'top' : 'bottom'"
    data-testid="fdropdown-menu"
    @click="() => $emit('click')"
  >
    <template v-slot:activator="{props}">
      <FButtonIcon
        v-if="icon"
        v-show="!shouldHide"
        v-bind="props"
        :square="square"
        :active="isOpen"
        :disabled="disabled"
        :input="input"
        :light="light"
        :icon="prependIcon"
        :outlined="outlined"
        :transparent="transparent"
        :small="small"
        :large="large"
        :size="btnSize"
        :icon-size="iconSize"
        :icon-stroke="iconStroke"
        :icon-fill="iconFill"
        :class="btnClass"
        :text="text"
        :data-testid="dataTestid"
        :data-cy="dataCy"
      />

      <FTagInput
        v-else-if="tagInput"
        v-bind="props"
        :id="activatorId"
        :inline-sectors="inlineSectors"
        :tags="tags"
        :item-value="itemValue"
        :item-text="itemText"
        :wrapper-class="wrapperClass"
        :icon="prependIcon"
        :default-path="defaultPath"
        :forced-level="forcedLevel"
        :use-simple-tags="useSimpleTags"
        :z-index="zIndex"
        :max-height="maxFieldHeight"
        machine-icons
        :on-change="onChange"
        :on-select-all="onSelectAll"
        :on-reset-all="onResetAll"
        :data-testid="dataTestid"
        :disabled="disabled"
        :display-machine-tags="displayMachineTags"
        :hide-sectors-groups="hideSectorsGroups"
        @on-confirm="$emit('on-confirm')"
      >
        <template v-slot:append-icon v-if="!hideChevron">
          <slot name="append-icon">
            <vue-feather
              tag="div"
              :size="chevronSize"
              :type="`chevron-${isOpen ? 'up' : 'down'}`"
              class="f-dropdown__append-icon"
            />
          </slot>
        </template>

        <template v-if="placeholder" #placeholder>
          {{ placeholder }}
        </template>
      </FTagInput>

      <FButton
        v-else
        v-show="!shouldHide"
        v-bind="props"
        left
        :id="activatorId"
        :active="isOpen"
        :disabled="disabled"
        :input="input"
        :light="light"
        :icon="prependIcon"
        :block="block"
        :outlined="outlined"
        :transparent="transparent"
        :constrain-content="constrainContent"
        :small="small"
        :medium="medium"
        :large="large"
        :height="btnSize"
        :icon-size="iconSize"
        :icon-stroke="iconStroke"
        :icon-fill="iconFill"
        :class="btnClass"
        :pink-button="pinkBorder"
        :data-testid="dataTestid"
        :tooltip="tooltip"
        :data-cy="dataCy"
      >
        <slot name="content">
          <div :class="['d-flex', 'flex-column', inRuleSection ? 'w-230' : '']">
            <span :class="textClass">
              {{ buttonText }}
            </span>

            <span v-if="helper" class="dropdown-btn-helper">
              {{ helper }}
            </span>
          </div>
        </slot>

        <template v-slot:append-icon v-if="!hideChevron">
          <slot name="append-icon">
            <vue-feather
              tag="div"
              :size="chevronSize"
              :type="`chevron-${isOpen ? 'up' : 'down'}`"
              class="f-dropdown__append-icon"
            />
          </slot>
        </template>
      </FButton>
    </template>

    <FDropdownList
      v-if="['default-search', 'default'].includes(type)"
      :value="modelValue"
      :item-text="itemText"
      :item-value="itemValue"
      :items="items"
      :return-object="returnObject"
      :tags="tags"
      :tag-included="tagIncluded"
      :multi-select="multiSelect"
      :default-selected="defaultSelected"
      :title="title"
      :class="listClass"
      :hide-search="type === 'default-search' ? false : true"
      @filter-list="filterList"
      @click="selectItem"
      @update-multi-select="onUpdateMultiSelect"
      :keep-scrollbar="keepScrollbar"
    >
      <template v-slot:item="item">
        <slot name="item" v-if="typeof item === 'object'" v-bind="item" />
        <slot name="item" v-else />
      </template>
      <template v-slot:append-item="item">
        <slot
          name="append-item"
          v-if="typeof item === 'object'"
          v-bind="item"
        />
        <slot name="append-item" v-else />
      </template>
      <template v-slot:prepend-item="item">
        <slot
          name="prepend-item"
          v-if="typeof item === 'object'"
          v-bind="item"
        />
        <slot name="prepend-item" v-else />
      </template>
      <template v-slot:item-prepend="item">
        <slot
          name="item-prepend"
          v-if="typeof item === 'object'"
          v-bind="item"
        />
        <slot name="item-prepend" v-else />
      </template>
      <template v-slot:item-append="item">
        <slot
          name="item-append"
          v-if="typeof item === 'object'"
          v-bind="item"
        />
        <slot name="item-append" v-else />
      </template>
    </FDropdownList>

    <FDropdownListUsers
      v-else-if="type === 'operator'"
      :on-select="onSelect"
      :description-field="descriptionField"
      :tag="tag"
      :operators="operators"
      :test-array="testArray"
      :hide-search="hideSearch"
      :title="title"
      :powered-by="poweredBy"
      :keep-scrollbar="keepScrollbar"
      :height="height"
      @close="() => closeMenu()"
    />

    <FDropdownListPerimeters
      v-else-if="type === 'perimeter' && isOpen"
      :on-change="onChange"
      :on-change-multiple="onChangeMultiple"
      :forced-level="forcedLevel"
      :default-path="defaultPath"
      :hide-search="hideSearch"
      :title="title"
      :single-select="singleSelect"
      :multi-select="multiSelect"
      :toggle-default="toggleDefault"
      :selected-sectors="tags"
      :on-select-all="onSelectAll"
      :on-reset-all="onResetAll"
      :can-select-all="canSelectAll"
      :keep-scrollbar="keepScrollbar"
      :keep-open="keepOpen"
      :mercateam-sectors="mercateamSectors"
      :only-select-last-level="onlySelectLastLevel"
      :display-machine-tags="displayMachineTags"
      :on-confirm="onConfirm"
      :hide-sectors-groups="hideSectorsGroups"
      :start-on-forced-level="startOnForcedLevel"
      @close="closeMenu"
    >
      <template v-slot:prepend-item>
        <slot name="prepend-item" />
      </template>
    </FDropdownListPerimeters>

    <slot class="dropdown-list" v-else />
  </v-menu>
</template>

<script lang="ts">
import {PropType, defineComponent, ref} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import FButton from "../Buttons/FButton.vue";
import FButtonIcon from "../Buttons/FButtonIcon.vue";
import FTagInput from "../Inputs/FTagInput.vue";
import FDropdownList from "./FDropdownList.vue";
import FDropdownListUsers from "./FDropdownListUsers.vue";
import FDropdownListPerimeters from "./FDropdownListPerimeters.vue";
import {
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  PROPS_FDROPDOWN_ITEM_VALUE_DEFAULT,
  TAG_SEPARATOR,
} from "@/config/constants";
import type {Sector} from "@/interfaces";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "figma-dropdown",
  components: {
    FButton,
    FButtonIcon,
    FDropdownList,
    FDropdownListUsers,
    FDropdownListPerimeters,
    FTagInput,
  },
  props: {
    modelValue: {type: [String, Object, Number], default: () => ({})},
    width: String,
    height: {type: String, default: "300px"},
    offset: {type: String, default: "8px"},
    keepOpen: {type: Boolean, default: false},
    hideSearch: Boolean,
    outlined: [Boolean, Array],
    block: Boolean,
    transparent: Boolean,
    icon: Boolean,
    input: Boolean,
    light: Boolean,
    shouldHide: Boolean,
    disabled: Boolean,
    square: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    constrainContent: Boolean,
    hideChevron: Boolean,
    keepScrollbar: Boolean,
    prependIcon: {type: String, default: ""},
    btnClass: {type: [String, Array, Object]},
    placeholder: String,
    title: String,
    label: String,
    transition: String,
    iconSize: String,
    btnSize: String,
    dataCy: {
      type: String,
      default: "fdropdown-button",
    },
    iconStroke: String,
    iconFill: String,
    chevronSize: Number,
    // the "default-search" type has been introduced to replicate the default logic with a searchable input
    type: {type: String, default: "default"},
    textClass: {type: String, default: ""},
    listClass: {type: String, default: ""},
    contentClass: {type: [String, Array, Object], default: () => []},
    textOverride: String,
    /**
     * helper text meant to be displayed below btn's text
     * will only be displayed when the dropdown is shown as a FButton
     */
    helper: {type: String, default: ""},
    fieldText: String,
    // Default dropdown props
    itemText: {type: String, default: "name"},
    itemValue: {type: String, default: PROPS_FDROPDOWN_ITEM_VALUE_DEFAULT},
    items: {type: Array, default: () => []},
    returnObject: Boolean,
    tagIncluded: Boolean,
    // Operator dropdown props
    onSelect: Function,
    descriptionField: {type: String, default: "descriptionField"},
    tag: {type: String, default: "operateur"},
    operators: {type: Array, default: () => []},
    testArray: {type: Array, default: () => []},
    // Perimeter dropdown props
    onChange: {type: Function, default: () => void 0},
    // prop holding the logic when validating the FDropdownListPerimeters dropdown
    onConfirm: {type: Function, default: () => null},
    startOnForcedLevel: {type: Boolean, default: false},
    forcedLevel: Object,
    defaultPath: Object,
    poweredBy: {type: String, default: ""},
    singleSelect: {type: Boolean, default: false},
    multiSelect: {type: Boolean, default: false},
    tagInput: {type: Boolean, default: false},
    tags: {type: Array, default: () => []},
    wrapperClass: {type: String, default: ""},
    toggleDefault: {type: Boolean, default: false},
    onSelectAll: {
      type: Function as PropType<
        (
          sector: Sector,
          level: {level: number},
          hasChildSelected?: boolean,
        ) => void
      >,
      default: () => void 0,
    },
    hideSectorsGroups: {type: Boolean, default: false},
    // `null` by default for the display condition
    onResetAll: {type: Function as PropType<() => void>, default: () => void 0},
    canSelectAll: {type: Boolean, default: false},
    mercateamSectors: {type: Boolean, default: false},
    // Tag input props
    useSimpleTags: {type: Boolean, default: false},
    onlySelectLastLevel: {type: Boolean, default: false},
    // default checked options ; only has sense if multiSelect is true
    defaultSelected: {type: Array, default: () => []},
    top: {type: Boolean, default: false},
    displayMachineTags: {type: Boolean, default: false},
    inlineSectors: {type: Boolean, default: false},
    pinkBorder: {type: Boolean, default: false},
    dataTestid: {type: String}, //for test purpose
    zIndex: {type: Number, default: 10000},
    maxFieldHeight: {type: String, default: "300px"},
    //special case for usage inside ParametersRuleSection
    inRuleSection: {type: Boolean, default: false},
    tooltip: {type: String, default: null},
    onChangeMultiple: {
      type: Function as PropType<(...args) => void>,
      default: null,
    },
    activatorId: {type: String, default: null},
  },
  emits: [
    "update:model-value",
    "click",
    "select",
    "change",
    "multi-select",
    "filter-list",
    "on-confirm",
  ],
  setup() {
    const mainStore = useMainStore();
    const {
      userData,
      isDevEnv,
      mercateamSectors: storeMercateamSectors,
      apiClient,
      stations,
    } = storeToRefs(mainStore);
    const isOpen = ref(false);

    function openDropdown() {
      isOpen.value = true;
    }

    return {
      userData,
      isDevEnv,
      storeMercateamSectors,
      apiClient,
      stations,
      isOpen,
      openDropdown,
    };
  },
  watch: {
    mercateamSectors: {
      immediate: true,
      handler: function (val: boolean) {
        if (val) this.loadMercaPerimeters();
      },
    },
    keepOpen: function (val: boolean, oldVal: boolean) {
      if (val === oldVal || !this.isOpen) return;
      if (!val) this.isOpen = false;
    },
  },
  computed: {
    buttonText(): string {
      const {type, textOverride, computedValue, placeholder, text} = this;
      let returned = null;
      if (["default-search", "default"].includes(type))
        returned = textOverride ?? computedValue;
      else if (type === "perimeter") returned = text;
      return returned || placeholder;
    },
    // Default dropdown computed values
    computedValue() {
      const {modelValue, itemText, itemValue, items, type} = this;
      let result = "";
      if (modelValue === undefined || modelValue === null) return null;
      if (typeof modelValue === "object" && this.returnObject) {
        result = modelValue[itemText];
        if (result == null) return null;
      } else if (!this.returnObject) {
        // for the specific case of a FDropdownList with a search input, the array of props.items being altered,
        // we force the displayed value to the current one
        if (type === "default-search") return modelValue;

        const tempValue =
          (items || []).find((x: any) => x[itemValue] === modelValue) ||
          (items || []).find((x: any) => x === modelValue);

        result = _.get(tempValue, itemText) || tempValue || "";
      } else result = modelValue;
      return (result + "" || "").replace(TAG_SEPARATOR, " - ");
    },
    text(): string {
      if (this.fieldText) return this.fieldText;
      if (this.mercateamSectors) {
        return (
          (this.storeMercateamSectors?.sectors_arr || []).find(
            (x: any) => x.id === this.modelValue,
          )?.name || ""
        );
      }
      return (
        (this.stations || []).find((x: any) => x.id === this.modelValue)
          ?.text || ""
      );
    },
    computedContentClass(): string {
      const {type, keepScrollbar, contentClass} = this;
      const scrollbar =
        keepScrollbar && !["operator", "perimeter"].includes(type);
      const classes: string[] = [
        CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
        contentClass,
        `dropdown-menu dropdown-menu-${type}`,
      ];
      if (scrollbar) classes.push("keep-scrollbar");
      return classes.join(" ");
    },
  },
  methods: {
    async loadMercaPerimeters() {
      const {userData, mercateamSectors} = this;
      let {storeMercateamSectors} = this;
      const {client_id} = userData || {};
      if (!client_id || !mercateamSectors) return;
      if (storeMercateamSectors?.sectors_arr?.length) return;
      const {sectors_arr, flat_structure} =
        (await this.apiClient.getMercateamSectors()) || {};
      this.storeMercateamSectors = {sectors_arr, flat_structure};
    },
    closeMenu() {
      this.isOpen = false;
    },
    // Default dropdown methods
    selectItem(item: any) {
      this.$emit("select", item);

      this.$emit(
        "update:model-value",
        this.returnObject
          ? item
          : typeof item === "object"
          ? item[this.itemValue]
          : item,
      );
      this.$emit("change", item);
    },

    onUpdateMultiSelect(items: any) {
      this.$emit("multi-select", items);
    },

    filterList(searchInputValue: string): void {
      this.$emit("filter-list", searchInputValue);
    },
  },
});
</script>

<style lang="scss">
div.v-application,
div.v-overlay-container {
  --default-chevron-size: 24px;

  .dropdown-menu,
  .v-overlay__content {
    border: 1px solid rgb(var(--v-theme-newSelected));
    border-radius: 8px;
    background-color: rgb(var(--v-theme-newLayerBackground));

    &-label {
      font-size: 16px;
      color: rgb(var(--v-theme-newSubText));
    }

    // Affects operator dropdowns
    .dropdown-list--users.v-list {
      &-item:not(.dropdown-search):not(.dropdown-title) {
        min-height: 40px;

        &.v-list-item:hover:not(.v-list-item--active) {
          background-color: rgb(var(--v-theme-newHover));
        }

        &.v-list-item--active {
          background-color: rgb(var(--v-theme-newPrimaryRegular));
          color: rgb(var(--v-theme-blanc));
        }
      }
    }

    // Affects perimeter dropdowns
    &-perimeter {
      overflow: visible;
      // dropdown height for perimeters is handled in FDropdownListPerimeters
      &:not(.force-max-height) {
        max-height: none !important;
      }
    }
    .dropdown-list {
      &:has([data-sticky]) {
        padding-bottom: 0 !important;
      }

      &--perimeters {
        .dropdown-header {
          position: sticky;
          top: 0;
        }

        .v-list-item:not(.dropdown-search):not(.dropdown-title) {
          min-height: 40px;

          &.nav-liste-item:not(.v-list-item--active):hover {
            background-color: rgb(var(--v-theme-newHover));
          }

          &.v-list-item--active {
            background-color: rgb(var(--v-theme-newPrimaryRegular));
            color: rgb(var(--v-theme-blanc));
            svg.feather {
              &:hover {
                background-color: rgb(var(--v-theme-newHover));
              }
            }
          }
        }

        .dropdown-content-perimeters {
          overflow-y: scroll;
          height: 100%;
        }
      }

      &--users {
        .dropdown-content-users {
          overflow-y: scroll;
          height: 100%;
        }
      }
    }

    // Affects all dropdowns
    .dropdown {
      &-item {
        min-height: 40px;

        &:hover:not(.v-list-item--active) {
          background-color: rgb(var(--v-theme-newHover));
        }

        &.v-list-item--active {
          background-color: rgb(var(--v-theme-newPrimaryRegular));
          color: rgb(var(--v-theme-blanc));
        }
      }

      &-header {
        border-bottom: 1px solid rgb(var(--v-theme-newSelected));
      }

      &-search {
        min-height: 0;
        height: 40px;
        margin: 16px;
      }

      &-title {
        margin-top: 8px;
        min-height: 0;
        height: 28px;
      }

      &-list {
        overflow-y: auto;
        height: 100%;
        border-radius: 8px;
        background-color: rgb(var(--v-theme-newLayerBackground));
      }

      &-list--perimeters {
        i.vue-feather {
          min-width: 24px;
        }

        svg.feather {
          border-radius: 4px;
          padding: 4px;

          &:hover {
            background-color: rgb(var(--v-theme-newHover));
          }
        }
      }
    }
  }

  .dropdown-btn-helper {
    font-size: 10px;
    color: rgb(var(--v-theme-newDisableText));
    text-align: left;
  }

  .big-text {
    font-size: 28px;
    line-height: 45px;
  }

  .w-100 {
    width: 100%;
  }

  .w-230 {
    width: 230px;
  }

  .nav-liste-item::before {
    background-color: transparent;
  }
  .f-dropdown__append-icon {
    flex: 0 0 var(--default-chevron-size);
  }
}
</style>
