import moment from "moment";
import _ from "lodash";
import loggerHelper from "@/tscript/loggerHelper";
import {i18n} from "@/i18n";
import {
  STOCKS_PROD_OBJ_TYPES,
  STOCKS_GRAPH_OBJECTIVE_OBJECT,
  STOCKS_GRAPH_DEMANDS_OBJECT,
  STOCKS_GRAPH_STOCKS_OBJECT,
  STOCKS_LOWEST_LEVEL_FIELD_NAME,
  STOCKS_DEFAULT_LOAD_QUANTITY_FIELD,
} from "@/config/constants";
import {AsyncResult, DATE_DEFAULT_FORMAT, Mesh} from "@oplit/shared-module";
import {n} from "@/tscript/utils/generalHelpers";
import {
  GenericConfigObject,
  Demand,
  Poste,
  Product,
  StocksFilter,
  StocksGroupBy,
  ProductsParams,
  LoadStocksParameters,
  StocksDetailedDataByDate,
} from "@/interfaces";
import {defineStore, storeToRefs} from "pinia";
import {ref, computed, unref} from "vue";
import {getSubperiodsArrayByMesh} from "@/tscript/utils/dateUtils";
import {
  getProductionObjectiveProductsArray,
  getWeightedKey,
} from "@/components/Simulation/Stocks/helpers";
import {useStocksUtils} from "@/composables/stocks/useStocksUtils";
import {useMainStore} from "@/stores/mainStore";
import {useAvailableLoadQuantityFields} from "@/composables/load/useAvailableLoadQuantityFields";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {onSnapshot} from "firebase/firestore";

type StocksBreadcrumbsItem = GenericConfigObject & {product?: Product};
/**
 * minimal mesh used for display of stocks data
 * this key is used for the definition of selectedProductData
 * for higher meshes we aggregate data based on the data of this minimal mesh
 */
const STOCKS_MINIMAL_MESH = "day";

export const useStocksStore = defineStore("stocks", () => {
  const {t} = i18n;
  const mainStore = useMainStore();
  const {stocksGroupByOptions, groupDemands} = useStocksUtils();
  const {
    currentDemandsLoadQuantityField,
    currentStocksLoadQuantityField,
    availableLoadQuantityFields,
    // getCurrentStocksLoadQuantityFieldConfig,
  } = useAvailableLoadQuantityFields();

  const selectedProduct = ref(null);
  const products = ref<Product[]>(null);
  const demands = ref<Demand[]>(null);
  const productsParams = ref<ProductsParams>(null);
  const stocksBreadcrumbs = ref<StocksBreadcrumbsItem[]>([]);
  const stocksGroupBy = ref<StocksGroupBy>(stocksGroupByOptions.value[0]);
  const stocksFilterBy = ref<StocksFilter>(null);
  const demandBreadcrumbs = ref<GenericConfigObject[]>([]);
  const isLoadingStocks = ref(false);
  const isLoadingDataByPeriod = ref(false);
  const isUpdatingStocks = ref(false);
  const demandGroupBy = ref<GenericConfigObject[]>([]);
  const availableUnits = ref<{
    unite_of: string;
    unite_op: string;
    unite_op_2?: string;
  }>(null);
  const stocksPeriod = ref<[string, string]>(null);
  const stocksSearch = ref<string>(null);
  const stocksMesh = ref<Mesh>("week");
  const selectedProductData = ref<StocksDetailedDataByDate>({
    [STOCKS_GRAPH_DEMANDS_OBJECT]: {},
    [STOCKS_GRAPH_OBJECTIVE_OBJECT]: {},
    [STOCKS_GRAPH_STOCKS_OBJECT]: {},
  });
  const locallyStoredProductsResponse = ref<Record<string, string | Product[]>>(
    {},
  );
  const stockCalculationStatusUnsub = ref<() => void>();
  const stocksRefreshIdx = ref(0);

  const getIsLoadingStocks = computed(() => isLoadingStocks.value);
  const getGroupedDemands = computed<Demand[]>(() => {
    const {userData} = storeToRefs(mainStore);
    const {client_id} = userData.value || {};
    return groupDemands(
      demands.value,
      demandGroupBy.value,
      demandBreadcrumbs.value,
      client_id,
    );
  });
  const getStocksFilteredProductsLastOp = computed<Product[]>(() => {
    return getStocksProducts.value.filter(
      (product: Product) => product.is_last_operation && !product.is_semi_fini,
    );
  });
  const getStocksSelectedProduct = computed<Product>(
    () => selectedProduct.value,
  );
  const getStocksProducts = computed<Product[]>(() => {
    const {field: lastBreadcrumbItemField, value: lastBreadcrumbItemValue} =
      getStocksBreadcrumbs.value[getStocksBreadcrumbs.value.length - 1] || {};
    return (products.value || []).map((p) => {
      const is_semi_fini =
        lastBreadcrumbItemField === "article_id" &&
        p.article_id !== lastBreadcrumbItemValue;
      return {...p, is_semi_fini};
    });
  });
  const getStocksDemands = computed<Demand[]>(() => demands.value);

  // Would both need to be renamed at some point
  const isSecondaryStocksUnit = computed(
    () => currentStocksLoadQuantityField.value !== "quantite_of",
  );
  const isSecondaryDemandsUnit = computed(
    () => currentDemandsLoadQuantityField.value !== "quantite_of",
  );

  const getStocksProductsCMJ = computed(() => {
    if (isSecondaryStocksUnit.value) return "n/a";
    if (!products.value?.length) return 0;
    return _.round(
      _.sumBy(
        getStocksFilteredProductsLastOp.value,
        (o: Product) => +(o.cmj || 0),
      ),
      0,
    );
  });
  const getStocksProductsDemand = computed(() => {
    if (isSecondaryStocksUnit.value) return "n/a";
    if (!products.value?.length) return 0;
    return _.round(
      _.sumBy(
        getStocksFilteredProductsLastOp.value,
        (o: Product) => +(o.demande || 0),
      ),
      0,
    );
  });
  const getStocksProductsObjective = computed(() => {
    if (!products.value?.length) return 0;
    return _.round(
      _.sumBy(
        getStocksFilteredProductsLastOp.value,
        (o: Product) =>
          +o[getWeightedKey("objective", isSecondaryStocksUnit.value)] || 0,
      ),
      0,
    );
  });
  const getStocksDelta = computed(() => {
    // returns the delta of stock
    if (!products.value?.length) return 0;
    const initialStock = _.sumBy(
      getStocksFilteredProductsLastOp.value,
      (o: Product) =>
        +o[getWeightedKey("initial_stock", isSecondaryStocksUnit.value)] || 0,
    );
    const finalStock = _.sumBy(
      getStocksFilteredProductsLastOp.value,
      (o: Product) =>
        +o[getWeightedKey("final_stock", isSecondaryStocksUnit.value)] || 0,
    );
    return _.round(finalStock - initialStock, 0);
  });
  const getWIPDelta = computed(() => {
    // returns the delta of wip
    if (!products.value?.length) return 0;
    const initialWIP = _.sumBy(
      getStocksFilteredProductsLastOp.value,
      (o: Product) =>
        +o[getWeightedKey("wip", isSecondaryStocksUnit.value)] || 0,
    );
    const finalWIP = _.sumBy(
      getStocksFilteredProductsLastOp.value,
      (o: Product) =>
        +o[getWeightedKey("final_wip", isSecondaryStocksUnit.value)] || 0,
    );
    return _.round(finalWIP - initialWIP, 0);
  });
  const getStocksBreadcrumbs = computed(() => stocksBreadcrumbs.value);
  const getDemandBreadcrumbs = computed(() => demandBreadcrumbs.value);
  const getDemandGroupBy = computed(() => demandGroupBy.value);
  const getStocksGroupBy = computed(() => stocksGroupBy.value);
  const getStocksFilterBy = computed(() => stocksFilterBy.value);
  const getIsGroupedStocks = computed(
    () =>
      stocksGroupBy.value?.field &&
      stocksGroupBy.value.field !== STOCKS_LOWEST_LEVEL_FIELD_NAME,
  );
  const getAvailableUnits = computed(() => availableUnits.value);
  const stocksSubperiods = computed(() =>
    getSubperiodsArrayByMesh(stocksPeriod.value, stocksMesh.value, {
      usePeriodBoundaries: true,
    }),
  );
  const selectedProductDataByMesh = computed(() => {
    if (stocksMesh.value === STOCKS_MINIMAL_MESH)
      return selectedProductData.value;
    else {
      return Object.entries(selectedProductData.value).reduce(
        (acc, [dataField, dataByDate]) => {
          return {
            ...acc,
            [dataField]: stocksSubperiods.value.reduce(
              (acc, {startDate, endDate}, periodIndex) => {
                const dataForPeriod = Object.entries<Record<string, number>>(
                  dataByDate,
                ).filter(([date]) => date >= startDate && date <= endDate);

                /**
                 * we sum the data on the current period to end up with a single
                 * {[field_1]: value_1, [field_2]: value_2, [field_3]: value_3} objec
                 * to set for the current period
                 */
                const aggregatedDataByField = _.mergeWith(
                  {},
                  /**
                   * array of [{[field_1]: value_1, [field_2]: value_2, [field_3]: value_3}, {[field_1]: ...}]
                   */
                  ...dataForPeriod.map(([_, value]) => value),
                  /**
                   * for the stocks object, we also want to include previous data
                   */
                  dataField === STOCKS_GRAPH_STOCKS_OBJECT && periodIndex > 0
                    ? _.last(Object.values(acc))
                    : {},
                  (objValue: number, srcValue: number) => {
                    return (objValue || 0) + srcValue;
                  },
                );

                return {
                  ...acc,
                  [startDate]: aggregatedDataByField,
                };
              },
              {},
            ),
          };
        },
        {},
      );
    }
  });

  function getProductDataByFieldAndDate(
    dataField: keyof StocksDetailedDataByDate,
    date: string,
  ): number {
    /**
     * for the production plan table & graph, we desynchronize the field usage from the dropdown of getAvailableStocksLoadQuantityFields
     * if this has to be synchronized, use the comment below as value for `field`
     * see comment in OPL-6632
     * https://linear.app/oplit/issue/OPL-6632/stock-changer-lobjectif-de-production-semaine-par-semaine#comment-41059ea7
     */
    const field = STOCKS_DEFAULT_LOAD_QUANTITY_FIELD; // getCurrentStocksLoadQuantityFieldConfig.value.key

    return (
      _.round(selectedProductDataByMesh.value[dataField][date]?.[field], 2) || 0
    );
  }

  async function saveProduct(product: Product): Promise<boolean> {
    const {apiClient} = storeToRefs(mainStore);
    try {
      const {article_id, secteur_id, stock_cible, num_sequence} = product;

      const response = await apiClient.value.postRequest(
        "/api/stocks/set_target_stock",
        {
          article_id,
          secteur_id,
          num_sequence,
          stock_cible: n(stock_cible, 0),
        },
      );

      return response.success;
    } catch (e) {
      return false;
    }
  }

  function listenToStockUpdates() {
    const {userData, simulation} = storeToRefs(mainStore);
    const client_id = userData.value?.client_id;
    const simulation_id = simulation.value?.id;
    if (!client_id || !simulation_id) return;

    //unsubscribe from previous call
    if (stockCalculationStatusUnsub.value) stockCalculationStatusUnsub.value();

    locallyStoredProductsResponse.value = {}; //reset the cache

    //watch for changes on stock in the simulation
    const watcherCallback = (data: {
      client_id: string;
      simulation_id: string;
      last_updated_at: string;
    }) => {
      if (!data) return;
      const {last_updated_at} = data;
      const localUpdatedAt = locallyStoredProductsResponse.value.updated_at;
      if (localUpdatedAt && last_updated_at <= localUpdatedAt) return;
      locallyStoredProductsResponse.value = {updated_at: last_updated_at};
    };
    stockCalculationStatusUnsub.value = dbHelper.watchDocumentChanges(
      "stock_calculation_status",
      `${client_id}_${simulation_id}`,
      watcherCallback,
    );
  }

  async function loadStocksProducts(parameters?: LoadStocksParameters) {
    const {silent = false, refresh = false} = parameters ?? {};
    const {apiClient, userData, simulation, siteId, stations} =
      storeToRefs(mainStore);
    const client_id = unref(userData)?.client_id;
    // .silent can be passed within the payload to prevent the various logics associated with getIsLoadingStocks
    isLoadingStocks.value = !silent;

    /**
     * if the @parameters contains {refresh: true}, we use the previously stored
     * query parameters to refresh the results
     */

    const params = refresh
      ? productsParams.value || {}
      : {
          period: stocksPeriod.value,
          simulation_id: simulation.value?.id || null,
          site_id: siteId.value,
          query_type: "stocks",
          client_id,
        };
    productsParams.value = params;

    const fullParams = {
      ...params,
      group_by: getStocksGroupBy.value,
      breadcrumbs: getStocksBreadcrumbs.value,
    };

    const uniqObj = {
      simulation_id: fullParams.simulation_id,
      breadcrumbs: fullParams.breadcrumbs.map(({value}) => value).join("/"),
      group_by: fullParams.group_by?.field,
      end_date: fullParams.period[1],
    };
    const uniqKey = Object.values(uniqObj).join("_");
    const previousValue = locallyStoredProductsResponse.value[uniqKey];
    const productsResponse =
      previousValue || (await apiClient.value.pgCustom(fullParams));

    if (!previousValue)
      locallyStoredProductsResponse.value[uniqKey] = productsResponse;

    stocksRefreshIdx.value++; // This is used to force a refresh of the stocks table. Very hacky, but we found no alternative. Cf https://gitlab.com/lbast/lbast-webapp/-/merge_requests/5014#note_2102001728

    products.value = Array.from(productsResponse || [], (product: Product) => {
      /**
       * the below block has been added to retrieve information about the entities in relation to this product
       * currently only required for the grouped view of the stocks table
       */
      const {secteur_id, parent_id} = product;
      const sectorMatch = stations.value.find(
        (poste: Poste) => poste.id === secteur_id,
      );
      if (!sectorMatch) return;
      const {name: secteur_name} = sectorMatch;
      const parentMatch = stations.value.find(
        (poste: Poste) => poste.id === parent_id,
      );
      /***/
      return {
        ...product,
        secteur_name,
        parent_name: parentMatch?.name,
      };
    }).filter(Boolean);

    isLoadingStocks.value = false;
  }

  const debouncedLoadStocksProducts = _.debounce(loadStocksProducts, 200);

  async function loadStocksDemands() {
    const {userData, simulation, siteId, apiClient} = storeToRefs(mainStore);
    const {client_id} = userData.value || {};
    isLoadingStocks.value = true;

    const params = {
      period: stocksPeriod.value,
      simulation_id: simulation.value?.id || null,
      site_id: siteId.value,
      query_type: "demands",
      client_id,
    };
    const demandsResponse = await apiClient.value.pgCustom(params);
    demands.value = demandsResponse;

    isLoadingStocks.value = false;
  }

  async function changeProductionObjective({type, products, date, value}) {
    if (!Object.values(STOCKS_PROD_OBJ_TYPES).includes(type)) return;
    if (!products?.length) return;
    const {userData, simulation, siteId, apiClient} = storeToRefs(mainStore);
    const {client_id} = userData.value || {};

    const {id: simulation_id, should_use_stocks_events} =
      simulation.value || {};

    const [startDate, endDate] = date;

    const cleanProducts = products.map(({is_semi_fini, ...rest}) => rest);

    try {
      isUpdatingStocks.value = true;

      await apiClient.value.setStocksTarget({
        type,
        value, // contains manually set value
        products: cleanProducts,
        client_id,
        simulation_id,
        startDate,
        endDate,
        site_id: siteId.value,
        should_use_stocks_events,
      });

      loadStocksProducts({refresh: true, silent: true});

      /**
       * we reload the data for the selected product:
       * - if we update the last operation : it has no effect on the graph & table otherwise
       * - if we are on the operation view, to update the graph data
       */
      if (
        getStocksSelectedProduct.value &&
        (cleanProducts.some(({is_last_operation}) => is_last_operation) ||
          getStocksBreadcrumbs.value.at(-1).field ===
            STOCKS_LOWEST_LEVEL_FIELD_NAME)
      )
        getProductDataByPeriod(getStocksSelectedProduct.value);
    } catch (e) {
      loggerHelper.error(e);
    } finally {
      isUpdatingStocks.value = false;
    }
    return;
  }

  function setSelectedProduct(product: Product) {
    selectedProduct.value = product;
  }
  function setStocksDemands(newDemands: Demand[]) {
    demands.value = newDemands;
  }

  function setStocksBreadcrumbs(breadcrumbs: StocksBreadcrumbsItem[]) {
    // const {setUserParameter} = mainStore;
    stocksBreadcrumbs.value = breadcrumbs;

    /**
     * FIXME:
     * we were storing the breadcrumbs in firestore to be able to init after a page refresh
     * since the stocks change we added the `product` in the breadcrumbs directly for various
     * reasons including proper data fetching
     * however firestore doesn't allow us to store objects with many keys such as the content of `product`
     * a single `product` could have its mandatory keys extracted however for family view, we need the content
     * of `product.children_articles` which would be too much data to be stored
     * therefore this is disabled for the time being
     */
    // setUserParameter({stocksBreadcrumbs: breadcrumbs});
  }

  function setDemandBreadcrumbs(breadcrumbs: GenericConfigObject[]): void {
    const {setUserParameter} = mainStore;
    demandBreadcrumbs.value = breadcrumbs;
    setUserParameter({demandBreadcrumbs: breadcrumbs});
  }

  function setDemandGroupBy(groupBy: GenericConfigObject[]): void {
    const {setUserParameter} = mainStore;
    demandGroupBy.value = groupBy;
    demandBreadcrumbs.value = [];
    setUserParameter({
      demandGroupBy: groupBy,
      demandBreadcrumbs: [],
    });
  }

  function setStocksFilterBy(filterBy: StocksFilter): void {
    const {setUserParameter} = mainStore;
    stocksFilterBy.value = filterBy;
    setUserParameter({
      stocksFilterBy: filterBy,
    });
  }

  function setStocksGroupBy(groupBy: StocksGroupBy): void {
    const {setUserParameter} = mainStore;
    stocksGroupBy.value = groupBy;
    stocksBreadcrumbs.value = [];
    setUserParameter({
      stocksGroupBy: groupBy,
      stocksBreadcrumbs: [],
    });
    // FIXME: this is triggered when we initializeStocksModuleFromParametresUser
    debouncedLoadStocksProducts({refresh: true});
  }

  /**
   * initializes state variables from firebase `parametres_user` table
   * FIXME: do it another way
   */
  function initializeStocksModuleFromParametresUser(): void {
    const {userParameters} = storeToRefs(mainStore);
    if (!userParameters.value) return;
    const syncStateVariables = [
      "stocksGroupBy",
      "stocksFilterBy",
      "demandGroupBy",
      // see `setStocksBreadcrumbs`
      // "stocksBreadcrumbs",
      "demandBreadcrumbs",
    ];
    for (const stateVariable of syncStateVariables) {
      if (userParameters.value[stateVariable]) {
        switch (stateVariable) {
          case "stocksGroupBy":
            stocksGroupBy.value = userParameters.value[stateVariable];
            break;
          case "stocksFilterBy":
            stocksFilterBy.value = userParameters.value[stateVariable];
            break;
          case "demandGroupBy":
            demandGroupBy.value = userParameters.value[stateVariable];
            break;
          case "stocksBreadcrumbs":
            stocksBreadcrumbs.value = userParameters.value[stateVariable];
            break;
          case "demandBreadcrumbs":
            demandBreadcrumbs.value = userParameters.value[stateVariable];
            break;
          default:
            break;
        }
      }
    }
  }

  async function getProductDataByPeriod(
    product: Product,
  ): AsyncResult<boolean> {
    const {userData, simulation, siteId, apiClient} = storeToRefs(mainStore);
    const {client_id} = userData.value || {};
    const {id: simulation_id} = simulation.value;
    const [startDate, endDate] = stocksPeriod.value;

    const products = getProductionObjectiveProductsArray(product);

    try {
      isLoadingDataByPeriod.value = true;

      const articles = products.map(
        ({article_id, secteur_id, is_last_operation, num_sequence}) => ({
          article_id,
          secteur_id,
          is_last_operation,
          num_sequence,
        }),
      );

      const promisesParameters = {
        site_id: siteId.value,
        should_match_sequence: true,
        articles,
        client_id,
        simulation_id,
        startDate,
        endDate,
      };

      const [demands, objectives] = await Promise.all([
        apiClient.value.getDemandByArticles(promisesParameters),
        apiClient.value.getProductionObjectiveByArticles(promisesParameters),
      ]);

      const getGroupedByDate = <T extends {day_date: string}>(array: T[]) =>
        _.groupBy(array || [], ({day_date}) =>
          moment(day_date)
            .startOf(STOCKS_MINIMAL_MESH)
            .format(DATE_DEFAULT_FORMAT),
        );

      const demandGroupedByDate = getGroupedByDate(demands);
      const objectiveGroupedByDate = getGroupedByDate(objectives);

      const fieldsKeys = availableLoadQuantityFields.value.map(({key}) => key);

      const getDataObjectByField = <T extends object>(array: T[]) =>
        fieldsKeys.reduce(
          (acc, field) => ({
            ...acc,
            [field]: _.sumBy(array, (item) => {
              /**
               *  FIXME: the fields from the array should be returned by the queries made above
               * this isn't the case for "quantite_op": the queries are returning the `quantite_op` under
               * the `load` key
               */
              const value =
                field === "quantite_op" && !(field in item)
                  ? +item["load"]
                  : +item[field];

              return value || 0;
            }),
          }),
          {} as Record<string, number>,
        );

      const demandAggregateByDate = Object.keys(demandGroupedByDate).reduce(
        (acc, date) => ({
          ...acc,
          [date]: getDataObjectByField(demandGroupedByDate[date]),
        }),
        {},
      );
      const objectiveAggregateByDate = Object.keys(
        objectiveGroupedByDate,
      ).reduce(
        (acc, date) => ({
          ...acc,
          [date]: getDataObjectByField(objectiveGroupedByDate[date]),
        }),
        {},
      );

      selectedProductData.value[STOCKS_GRAPH_DEMANDS_OBJECT] =
        demandAggregateByDate;
      selectedProductData.value[STOCKS_GRAPH_OBJECTIVE_OBJECT] =
        objectiveAggregateByDate;

      const initial_stock = _.sumBy(
        products,
        ({initial_stock}) => +initial_stock || 0,
      );

      const minimalStocksSubperiods = getSubperiodsArrayByMesh(
        stocksPeriod.value,
        STOCKS_MINIMAL_MESH,
        {
          usePeriodBoundaries: true,
        },
      );

      // constructs the STOCKS_GRAPH_STOCKS_OBJECT from the demand & objective
      selectedProductData.value[STOCKS_GRAPH_STOCKS_OBJECT] =
        minimalStocksSubperiods.reduce(
          (
            acc: StocksDetailedDataByDate[keyof StocksDetailedDataByDate],
            {startDate}: {startDate: string},
            index: number,
          ) => {
            const initialStock = index > 0 ? 0 : initial_stock;

            const demand =
              selectedProductData.value[STOCKS_GRAPH_DEMANDS_OBJECT][startDate];

            const objective =
              selectedProductData.value[STOCKS_GRAPH_OBJECTIVE_OBJECT][
                startDate
              ];

            acc[startDate] = fieldsKeys.reduce(
              (acc, field) => ({
                ...acc,
                [field]:
                  initialStock +
                  (+objective?.[field] || 0) -
                  (+demand?.[field] || 0),
              }),
              {},
            );

            return acc;
          },
          {},
        );

      return [true, null];
    } catch (error) {
      return [null, error];
    } finally {
      isLoadingDataByPeriod.value = false;
    }
  }

  function getProductionPlanTableHeaderDisplay({
    startDate,
  }: {
    startDate: string;
  }) {
    if (stocksMesh.value === "week")
      return `${t("global.week_short")}.${moment(startDate).format("WW")}`;

    return _.capitalize(moment(startDate).format("MMMM"));
  }

  return {
    selectedProduct,
    getIsLoadingStocks,
    getGroupedDemands,
    getStocksSelectedProduct,
    getStocksProducts,
    getStocksDemands,
    isSecondaryStocksUnit,
    isSecondaryDemandsUnit,
    getStocksProductsCMJ,
    getStocksProductsDemand,
    getStocksProductsObjective,
    getStocksDelta,
    getWIPDelta,
    getStocksBreadcrumbs,
    getDemandBreadcrumbs,
    getDemandGroupBy,
    getStocksGroupBy,
    getStocksFilterBy,
    getIsGroupedStocks,
    getAvailableUnits,
    saveProduct,
    loadStocksProducts,
    loadStocksDemands,
    changeProductionObjective,
    setSelectedProduct,
    setStocksDemands,
    setStocksBreadcrumbs,
    setDemandBreadcrumbs,
    setDemandGroupBy,
    setStocksFilterBy,
    setStocksGroupBy,
    initializeStocksModuleFromParametresUser,
    stocksPeriod,
    stocksSearch,
    stocksMesh,
    selectedProductDataByMesh,
    stocksSubperiods,
    isUpdatingStocks,
    isLoadingStocks,
    getProductDataByPeriod,
    getProductDataByFieldAndDate,
    isLoadingDataByPeriod,
    listenToStockUpdates,
    stocksRefreshIdx,
    getProductionPlanTableHeaderDisplay,
  };
});
