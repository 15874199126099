<template>
  <v-lazy
    :min-height="35"
    @update:model-value="loadPostRowDisplay"
    class="gantt-row__lazy-wrapper"
  >
    <div class="gantt-row--wrapper">
      <div class="gantt-row" :style="getRowStyle">
        <GanttRowPrefix
          :entity="superEntity"
          :hide-progress-rate="hideProgressRate"
          :loading="loading"
          :is-synthetic-view="isSyntheticView"
        />

        <div class="gantt-row--cell-wrapper">
          <div
            v-for="(day, dayIndex) in ganttDaysArray"
            :key="getGanttRowCellKey(day, dayIndex)"
            :class="getDayClasses(day, dayIndex)"
          >
            <v-skeleton-loader v-if="loading" boilerplate type="button" />
            <template v-else>
              <v-tooltip
                v-for="operation in getDisplayedOperations(
                  getOperationsPerDay(day, dayIndex),
                )"
                :key="`${getGanttOperationKey(operation)}-${
                  operation.day_date
                }`"
                content-class="gantt-row--cell-tooltip"
                location="top"
              >
                <template v-slot:activator="{props}">
                  <div
                    :class="getOperationClasses(operation)"
                    :style="getOperationStyle(operation, dayIndex)"
                    @mouseover="setHoveredOperation(operation)"
                    @mouseleave="setHoveredOperation(null)"
                    @click="onOperationClick(operation)"
                    v-click-outside="(e) => onClickOutsideCard(e, operation)"
                  >
                    <div
                      v-bind="props"
                      :class="[
                        'gantt-row--cell-operation-container',
                        {'is-synthetic': isSyntheticView},
                      ]"
                    >
                      <div
                        :class="[
                          'gantt-row--cell-operation-information',
                          CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
                        ]"
                      >
                        <div
                          class="gantt-row--cell-operation-information-title"
                        >
                          <span class="gantt-row--cell-operation-header">
                            <strong>
                              {{ getOperationTitle(operation) }}
                            </strong>
                            <OperationPriorityChip
                              v-if="
                                hasPriority(operation) && ganttMesh !== 'month'
                              "
                              :op="operation"
                              compact
                              read-only
                            />
                          </span>
                          <span
                            v-if="!isList && operation.designation_article"
                            class="text-newDisableText font-weight-normal"
                          >
                            {{ operation.designation_article }}
                          </span>
                        </div>
                      </div>

                      <vue-feather
                        v-if="isHoveredOperation(operation)"
                        type="maximize-2"
                        :size="isSyntheticView ? '14px' : '16px'"
                        class="gantt-row--cell-operation-expand"
                        @click.stop="$openOFSidebar(operation)"
                      />
                    </div>

                    <OperationsActionsMenu
                      v-bind="getActionsMenuPositionalProps(dayIndex)"
                      :selected-ops="selectedOps"
                      :show-actions-menu="isFirstSelectedOperation(operation)"
                      :arrows-state="getArrowsState(dayIndex)"
                      :is-operation-ongoing="silentLoading"
                      :has-done-ops="hasDoneOps"
                      top="0"
                      hide-vertical-arrows
                      @trigger-key-event="triggerKeyEvent"
                      @is-displayed="(v) => (isMenuShown = v)"
                    />
                  </div>
                </template>

                <SchedulingOperationCard
                  :id="operation.op_id"
                  :operation="operation"
                  :order="dayIndex + 1"
                  :is-synthetic-view="isSyntheticView"
                  :has-done-ops="hasDoneOps"
                  compact
                />
              </v-tooltip>
            </template>
          </div>

          <div
            v-if="
              !isFirstAvailableOpVisible && !!formattedMinPlannedDate && isList
            "
            :class="
              isFirstAvailableOpInThePast
                ? 'position-to-left'
                : 'position-to-right'
            "
            class="gantt-row--cell-unfolding-button"
            data-testid="gantt-row--cell-unfolding-button"
          >
            <UnfoldingButton
              :unfolding-direction="
                isFirstAvailableOpInThePast ? 'right' : 'left'
              "
              :icon="isFirstAvailableOpInThePast ? 'arrow-left' : 'arrow-right'"
              @click.stop="changePeriodStartDate"
            >
              {{ formattedMinPlannedDate }}
            </UnfoldingButton>
          </div>
        </div>
      </div>

      <div
        v-if="getMaxOperationsCount > 1 && !this.isDisabledShowAll"
        class="see-more"
        @click="onShowAllClick"
      >
        {{ displayedSeeMoreText }}

        <vue-feather :type="showAll ? 'chevron-up' : 'chevron-down'" />
      </div>
    </div>
  </v-lazy>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  ref,
  unref,
  onUnmounted,
  watch,
} from "vue";
import {PropType} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";

import {UnfoldingButton} from "@/components/Global";
import {
  OperationPriorityChip,
  OperationsActionsMenu,
} from "@/components/Scheduling/Operations";
import SchedulingOperationCard from "@/components/Scheduling/Operations/SchedulingOperationCard.vue";
import GanttRowPrefix from "./GanttRowPrefix.vue";
import {
  computedDisplayFn,
  pgOpsMapFn,
  filterSchedulingOperations,
  getGanttOperationKey,
  onClickOutsideSchedulingOperation,
  isSelectedOperation,
  getGanttInterestKey,
  areSchedulingFiltersActive,
  getColorCategoryClass,
  schedulingStatusColorBg,
  hasArrayDoneOperations,
} from "@/tscript/utils/schedulingUtils";
import {
  mapOpsWithGanttRowIndex,
  isWorkDay,
  getNewDateFromShift,
  LoadEventRowEventInfos,
  getOperationStatus,
} from "@oplit/shared-module";
import {
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  CSS_OPERATION_CARD_SELECTED_CLASS,
  CSS_SCHEDULING_GANTT_CELL_CLASS,
  DATE_DEFAULT_FORMAT,
  OF_STATUS,
} from "@/config/constants";

import type {
  GanttableEntity,
  HTMLElementStyleObject,
  OperationsActionsMenuSelectedOps,
  SchedulingFilter,
  SchedulingOperation,
  VueClassesArray,
} from "@/interfaces";
import moment from "moment";

import {useMainStore} from "@/stores/mainStore";
import {useParameterStore} from "@/stores/parameterStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useGanttStore} from "@/stores/ganttStore";
import {usePGSubscriptions} from "@/composables/pgComposable";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";

export default defineComponent({
  name: "gantt-row",
  components: {
    OperationsActionsMenu,
    SchedulingOperationCard,
    GanttRowPrefix,
    OperationPriorityChip,
    UnfoldingButton,
  },
  props: {
    entity: {type: Object as PropType<GanttableEntity>, required: true},
    hideProgressRate: {type: Boolean, default: false},
    selectedOperations: {
      type: Array as PropType<SchedulingOperation[]>,
      default: () => [],
    },
    filters: {type: Array as PropType<SchedulingFilter[]>, default: () => []},
    filterDoneOps: {type: Boolean, default: false},
    isList: {type: Boolean, default: false},
    isPiloting: {type: Boolean, default: false},
    isSyntheticView: {type: Boolean, default: false},
    selectedDelayMesh: {
      type: Object as PropType<{label?: string; mesh?: string; value?: number}>,
      default: () => ({}),
    },
    isLast: {type: Boolean, default: false},
    dailyLoadRates: {
      type: Object as PropType<
        Record<
          string,
          {
            value: string | number;
            load: number;
            capa: number;
            unit: string;
            loadDetail: {of_id: string; load: number}[];
            mappedOpsWithDailyLoad: {
              of_id: string;
              load: number;
            }[];
          }
        >
      >,
      required: true,
    },
    isActive: {type: Boolean, default: false},
  },
  emits: ["operations-loaded", "operation-clicked", "operations-unloaded"],
  setup(props) {
    const getGanttIsPastDelayShown = inject("getGanttIsPastDelayShown");

    const mainStore = useMainStore();
    const {
      machines,
      userData,
      calendars,
      apiClient,
      environment,
      pgRefresh,
      clientParameters,
      stations,
      teamIdBySector,
      userParameters,
    } = storeToRefs(mainStore);
    const {getCtxSectorOrderedCalendars, getCtxNextAvailableDate} = mainStore;

    const hasBeenWithinViewport = ref(false);

    const {
      parametersSchedulingDisplay,
      doesClientHaveShiftPlanning,
      clientFirstShift,
      parametersAllSchedulingDisplaysByTeamId,
    } = storeToRefs(useParameterStore());
    const {getDailySchedulingColors, setTotalLoadBySectorFromOperations} =
      useSchedulingStore();
    const {
      selectedSimulation,
      schedulingCurrentColorCategory,
      calendarOfIdsToExport,
      pilotingOfIdsToExport,
      pgOpsModifications,
      schedulingMachineCentersAndTags,
      totalCapaBySector,
      messagesByOfId,
    } = storeToRefs(useSchedulingStore());
    const {loadGanttOperations, getStartDateForDelay} = useGanttStore();
    const {
      ganttMesh,
      ganttHideWeekend,
      ganttIsSyntheticView,
      ganttIsCompactView,
      ganttEntitiesToReload,
      ganttPeriod,
      ganttDaysArray,
      ganttPeriodStartDate,
      ganttDateUpdate,
      ganttSectorCapacities,
    } = storeToRefs(useGanttStore());
    const {pg_init, pg_subscribe} = usePGSubscriptions();
    const {getSelectedOperationDetail} = useComputeQuantityUnit();

    const pg_ops = ref<SchedulingOperation[]>([]);
    const isMenuShown = ref(false);

    const messages = computed(() => {
      const {of_id} = (props.entity || {}) as {of_id: string};
      if (!of_id) return [];
      return messagesByOfId.value[of_id] || [];
    });

    const superEntity = computed(() => {
      const min_planned_date = pg_ops.value.find(
        ({min_planned_date}) => !!min_planned_date,
      )?.min_planned_date;
      const max_planned_date = pg_ops.value.find(
        ({max_planned_date}) => !!max_planned_date,
      )?.max_planned_date;
      return {
        messages: messages.value,
        ...(props.entity as GanttableEntity),
        operations: pg_ops.value,
        ...(min_planned_date && {min_planned_date}),
        ...(max_planned_date && {max_planned_date}),
      };
    });

    const processedOperations = computed<SchedulingOperation[]>(() => {
      const ops = Array.from(
        superEntity.value.operations || [],
        (operation: SchedulingOperation) => ({
          ...operation,
          /**
           * we need to have a value defined for the op_duration for the sort comparison to work
           * the fallback value shouldn't matter since we use the css max() for the operation width
           */
          op_duration: operation.op_duration ?? 24,
        }),
      );

      const filteredOps = filterSchedulingOperations(ops, props.filters);
      if (!areSchedulingFiltersActive(props.filters))
        return sortOperations(filteredOps);

      const reindexedOps = mapOpsWithGanttRowIndex(
        _.sortBy(filteredOps, "min_date"),
        moment(unref(ganttPeriod).at(0)).format(DATE_DEFAULT_FORMAT),
        ganttIsCompactView.value,
      );

      return sortOperations(reindexedOps);
    });

    const selectedOps = computed((): OperationsActionsMenuSelectedOps => {
      if (!props.selectedOperations.length) return {};

      return props.selectedOperations.reduce(
        (acc, operation) => ({
          ...acc,
          [operation.op_id]: getSelectedOperationDetail(operation),
        }),
        {} as OperationsActionsMenuSelectedOps,
      );
    });

    function sortOperations(
      operations: SchedulingOperation[],
    ): SchedulingOperation[] {
      return _.orderBy(
        operations,
        [
          (o: SchedulingOperation) => o.new_date || o.day_date,
          "op_duration",
          (o: SchedulingOperation) => o.initial_date,
        ],
        ["asc", "desc", "asc"],
      );
    }

    function changePeriodStartDate(): void {
      const minPlannedDate = (superEntity.value as {min_planned_date: string})
        .min_planned_date;

      ganttPeriodStartDate.value = {
        startDate: minPlannedDate,
        monthDate: minPlannedDate,
        bypassSlipperyView: true,
      };
    }

    const getGanttRowCellKey = (day, dayIndex) => {
      if (dayIndex) return `cell-${day}`;
      return `cell-${day}-${getGanttIsPastDelayShown}`;
    };

    const ofIdsToExport = computed<string[]>(() => {
      const hasActiveFilters = props.filters.some(
        ({selectedItem}) => selectedItem.length,
      );
      if (!hasActiveFilters && props.isList) return [];
      return _.uniqBy(unref(processedOperations), (op) => op.of_id).map(
        ({of_id}) => of_id,
      );
    });

    function getBackgroundColorFromStatus(operation: SchedulingOperation) {
      const status = getOperationStatus(operation);
      if (status === OF_STATUS.DONE) return "bg-newLightGrey";
      return `bg-${schedulingStatusColorBg(status)}`;
    }

    watch(
      () => pgOpsModifications.value,
      (newOps: SchedulingOperation[]) => {
        if (props.isList) return; //On impacte uniquement la vue calendrier, la vue Gantt a son propre mécanisme
        const {secteur_id} = props.entity as {secteur_id: string};
        if (!secteur_id) return;

        const updatedOps = pg_ops.value
          .map((x) => {
            const match = newOps.find((o) => o.op_id === x.op_id);
            if (!match) return x;
            const match_secteur_id = match.new_secteur_id || match.secteur_id;
            if (match_secteur_id && match_secteur_id !== secteur_id) return;
            return {...x, ...match};
          })
          .filter(Boolean);
        const movedOps = newOps.filter(
          (o) =>
            (o.new_secteur_id || o.secteur_id) === secteur_id &&
            !updatedOps.some((x) => x.op_id === o.op_id),
        );
        pg_ops.value = [...updatedOps, ...movedOps];
      },
      {deep: true},
    );

    watch(ganttIsCompactView, (isCompact) => {
      if (props.isList || !pg_ops.value.length) return;
      pg_ops.value = mapOpsWithGanttRowIndex(
        _.orderBy(pg_ops.value, ["day_date", "op_name", "op_id"]),
        moment(unref(ganttPeriod).at(0)).format(DATE_DEFAULT_FORMAT),
        isCompact,
      );
    });

    watch(
      () => [
        processedOperations.value,
        userParameters.value.include_filters_in_load_rate,
      ],
      ([newFilteredOps, newIncludeFiltersInLoadRate]) => {
        if (props.isList) return;

        const operations = newIncludeFiltersInLoadRate
          ? newFilteredOps
          : pg_ops.value;

        setTotalLoadBySectorFromOperations(
          operations.filter(({op_status}) => op_status !== OF_STATUS.DONE),
          props.entity as {secteur_id: string},
        );
      },
    );

    onUnmounted(() => {
      if (props.isList) return;
      const id = (props.entity as {secteur_id: string})?.secteur_id;
      calendarOfIdsToExport.value = _.omit(unref(calendarOfIdsToExport), id);
      pilotingOfIdsToExport.value = _.omit(unref(pilotingOfIdsToExport), id);
    });

    return {
      hoveredOperation: ref<SchedulingOperation>(null),
      /**
       * FIXME:
       * the original "show all" display was too visible and is currently being reworked
       * since the associated logic is to be kept, the adjustments below have been made :
       * - `showAll` now defaults to true to have the full display by default
       * - `isDisabledShowAll` is just present to hide the bottom toggler
       */
      isDisabledShowAll: ref<boolean>(true),
      showAll: ref<boolean>(true),
      /**
       * contains operations linked to the OF (should probably be fetched before ?)
       */
      pg_ops,
      /**
       * contains user messages for this OF (to display the "comment" icon inside the prefix)
       */
      messages,
      loading: ref<boolean>(false),
      // setting this to `true` will prevent the visible loading effects from occurring when fetching load
      silentLoading: ref<boolean>(false),
      OF_STATUS,
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      machines,
      userData,
      calendars,
      apiClient,
      pgRefresh,
      parametersSchedulingDisplay,
      getDailySchedulingColors,
      selectedSimulation,
      schedulingCurrentColorCategory,
      loadGanttOperations,
      getStartDateForDelay,
      ganttMesh,
      ganttHideWeekend,
      ganttIsSyntheticView,
      ganttIsCompactView,
      getGanttIsPastDelayShown,
      ganttEntitiesToReload,
      ganttPeriod,
      ganttDaysArray,
      ganttPeriodStartDate,
      changePeriodStartDate,
      superEntity,
      processedOperations,
      sortOperations,
      getGanttRowCellKey,
      ofIdsToExport,
      calendarOfIdsToExport,
      pilotingOfIdsToExport,
      ganttDateUpdate,
      ganttSectorCapacities,
      environment,
      getCtxSectorOrderedCalendars,
      getCtxNextAvailableDate,
      doesClientHaveShiftPlanning,
      clientFirstShift,
      hasBeenWithinViewport,
      isMenuShown,
      clientParameters,
      getBackgroundColorFromStatus,
      pg_init,
      pg_subscribe,
      stations,
      totalCapaBySector,
      schedulingMachineCentersAndTags,
      teamIdBySector,
      parametersAllSchedulingDisplaysByTeamId,
      selectedOps,
    };
  },
  computed: {
    isFirstAvailableOpVisible(): boolean {
      const {superEntity, ganttDaysArray} = this;
      const minPlannedDate = superEntity.min_planned_date;
      return (
        ganttDaysArray.at(0) <= minPlannedDate &&
        minPlannedDate <= ganttDaysArray.at(-1)
      );
    },
    isFirstAvailableOpInThePast(): boolean {
      const {superEntity, ganttDaysArray} = this;
      return superEntity.min_planned_date < ganttDaysArray.at(0);
    },
    formattedMinPlannedDate(): string {
      if (this.superEntity.min_planned_date)
        return moment(this.superEntity.min_planned_date).format("LL");
      return "";
    },
    hasDoneOps(): boolean {
      return hasArrayDoneOperations(this.selectedOperations);
    },
    areThereHiddenOps(): boolean {
      return this.getMaxOperationsCount > 1 && !this.showAll;
    },
    /**
     * returns the max number of operations within a column
     * used for row height calculation
     * NB: operations returned by the fetch query may exceed UI boundaries (related to operation smoothing over multiple days)
     */
    getMaxOperationsCount(): number {
      if (!this.pg_ops?.length) return 0;
      const ops = this.processedOperations.filter((op: SchedulingOperation) =>
        this.ganttDaysArray.includes(
          // we need to take into account day_dates formatted with hours/minutes
          moment(op.day_date).format(DATE_DEFAULT_FORMAT),
        ),
      );

      const max = (_.max(_.map(ops, "gr_idx")) ?? 0) + 1;
      if (!this.getGanttIsPastDelayShown) return max;

      const pastOperations = this.processedOperations.filter(
        (op: SchedulingOperation) =>
          moment(op.day_date).isSameOrBefore(this.ganttDaysArray[0]),
      );
      return Math.max(max, pastOperations.length);
    },
    /**
     * returns the height for a row
     */
    getRowStyle(): HTMLElementStyleObject {
      if (!this.getMaxOperationsCount) return {};

      /**
       * for the list view, the content inside details/tags columns can exceed the operations-height-determined height\
       * same goes for the piloting view with the progress rate being displayed
       */
      const prefixHeightExceedsOperationsContainer =
        (this.isList || this.isPiloting) && this.getMaxOperationsCount <= 1;

      return {
        height:
          this.areThereHiddenOps || prefixHeightExceedsOperationsContainer
            ? "auto"
            : `calc(${
                this.getMaxOperationsCount
              } * (var(--gantt-operation-height) + var(--gantt-operation-spacing)) + var(--gantt-operation-spacing) + 1px ${
                this.isLast && this.isMenuShown
                  ? this.ganttIsSyntheticView
                    ? "+ 90px"
                    : "+ 50px"
                  : ""
              })`,
        "min-height":
          "calc(var(--gantt-operation-height) + var(--gantt-operation-spacing) * 2)",
      };
    },
    displayedSeeMoreText(): string {
      return this.showAll
        ? this.$t("scheduling.see_less")
        : this.$t("scheduling.see_more");
    },
  },
  watch: {
    ganttDateUpdate({value}: {value: number}) {
      const selectedOperationsFromEntity = this.selectedOperations.filter(
        (op) =>
          op[getGanttInterestKey(this.isList)] ===
          this.entity[getGanttInterestKey(this.isList)],
      );
      if (selectedOperationsFromEntity.length > 0)
        this.moveIndividualCards(value);
    },
    async ganttEntitiesToReload(newV: {
      sectorIds?: string[];
      ofIds?: string[];
    }) {
      const values = (this.isList ? newV.ofIds : newV.sectorIds) || [];
      if (values.includes(this.entity[getGanttInterestKey(this.isList)]))
        await this.loadCharge();

      // FIXME: disabled as per `isDisabledShowAll` explanation
      // this.handleShowAllDisplay(newV);
    },
    async ganttPeriod(newPeriod, oldPeriod) {
      if (!_.isEqual(newPeriod, oldPeriod))
        await this.loadPostRowDisplay(this.isActive);
    },
    async isActive(newBool) {
      if (newBool) this.loadPostRowDisplay(newBool);
      else this.hasBeenWithinViewport = false;
    },
    pg_ops(newOperations) {
      this.$emit("operations-loaded", newOperations);
    },
    async getGanttIsPastDelayShown(newBool) {
      if (newBool) await this.loadCharge();
    },
    async selectedDelayMesh() {
      await this.loadCharge();
    },
    async entity() {
      this.loadCharge();
    },
    ofIdsToExport(newVal: string[]) {
      if (this.isList) return;
      const id = this.entity.secteur_id;
      if (this.isPiloting) {
        this.pilotingOfIdsToExport = {
          ...this.pilotingOfIdsToExport,
          [id]: newVal,
        };
      } else {
        this.calendarOfIdsToExport = {
          ...this.calendarOfIdsToExport,
          [id]: newVal,
        };
      }
    },
    pgRefresh(val: number) {
      if (val) this.loadCharge();
    },
  },
  created() {
    this.pg_init();
    this.pg_subscribe(["daily_prod"], () => this.loadCharge());
    document.addEventListener("keydown", this.keyDownEventHandler);
    document.addEventListener("keyup", this.keyUpEventHandler);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.keyDownEventHandler);
    document.removeEventListener("keyup", this.keyUpEventHandler);
    this.$emit("operations-unloaded", this.entity);
  },
  methods: {
    async loadPostRowDisplay(isActive: boolean): Promise<void> {
      if (!isActive && !this.hasBeenWithinViewport) return;
      this.hasBeenWithinViewport = isActive;
      await this.loadCharge();
    },
    async loadCharge() {
      this.loading = !this.silentLoading;
      if (!this.silentLoading) this.pg_ops = [];
      if (this.entity.of_id) {
        const operations = await this.loadChargeOf();

        this.pg_ops = operations || [];
      } else {
        let startDateForDelay = "";
        if (this.getGanttIsPastDelayShown)
          startDateForDelay = this.getStartDateForDelay(this.selectedDelayMesh);

        const [, operations] = await this.loadGanttOperations({
          sectors: [this.entity],
          params: {
            filter_done_ops: this.filterDoneOps,
            ...(startDateForDelay && {startDateForDelay}),
          },
        });

        this.pg_ops = operations;
      }
      this.silentLoading = false;
      // delay to have a smoother display of the spinner
      this.loading = false;
    },
    async loadChargeOf() {
      const {
        entity: {of_id},
        selectedSimulation,
        ganttPeriod,
        filterDoneOps,
      } = this;
      const {id: simulation_id} = selectedSimulation || {};
      if (!simulation_id || !of_id) return;
      const startDate = ganttPeriod[0].format(DATE_DEFAULT_FORMAT);
      const endDate = ganttPeriod[1].format(DATE_DEFAULT_FORMAT);
      const results = await this.apiClient.ofsOpsFn(simulation_id, of_id, {
        prepare_gantt_data: true,
        start_date: startDate,
        end_date: endDate,
        is_weekend_hidden: this.ganttHideWeekend,
        calendars: this.getCtxSectorOrderedCalendars(),
      });
      return pgOpsMapFn(results, {
        of_ordering: true,
        keepOpsDone: !filterDoneOps,
        removeSmoothedDuplicates: true,
      });
    },
    hasPriority(operation: SchedulingOperation): boolean {
      return !!operation.fast_track;
    },
    getOperationTitle(operation: SchedulingOperation): string {
      const {
        parametersSchedulingDisplay,
        isList,
        teamIdBySector,
        parametersAllSchedulingDisplaysByTeamId,
      } = this;

      const sectorTeamId = teamIdBySector[operation.secteur_id];
      const sector_scheduling_display =
        parametersAllSchedulingDisplaysByTeamId?.[sectorTeamId] ||
        parametersSchedulingDisplay;

      const computedDisplay = computedDisplayFn(
        operation,
        sector_scheduling_display,
        {
          theoric_date: operation.max_date_of,
          custom_view: isList ? "list_view" : "calendar_view",
          client_name: this.userData.client_name,
          environment: this.environment,
        },
      );
      return computedDisplay?.header;
    },
    getDayClasses(day: string, dayIndex: number): VueClassesArray {
      const classes = [CSS_SCHEDULING_GANTT_CELL_CLASS, "gantt-row--cell"];
      if (moment().isSame(moment(day), "day"))
        classes.push(this.getDailySchedulingColors(day, true) ?? "is-today");

      if (this.isList) return classes;

      const populatedEntity = (
        this.entity.is_machine
          ? this.schedulingMachineCentersAndTags
          : this.stations
      ).find(({id}) => id === this.entity.secteur_id);

      if (!populatedEntity) return classes;

      const totalCapaForEntity = this.totalCapaBySector[populatedEntity.id];

      if (!totalCapaForEntity) return classes;

      const totalCapaForEntityAndDay = totalCapaForEntity.find(
        ({day_date}) => day_date === day,
      );

      if (
        totalCapaForEntityAndDay?.daily_capa === undefined &&
        // we do not display the background for the column gathering past operations even tho the day itself is closed
        (dayIndex > 0 || !this.getGanttIsPastDelayShown)
      )
        return [...classes, "bg-newLightGrey"];

      return classes;
    },
    getOperationClasses(operation: SchedulingOperation): VueClassesArray {
      const classes = [
        "gantt-row--cell-operation",
        CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
        this.getBackgroundColorFromStatus(operation),
      ];
      if (this.isSyntheticView) classes.push("is-synthetic");
      if (isSelectedOperation(this.selectedOperations, operation)) {
        classes.push(CSS_OPERATION_CARD_SELECTED_CLASS);
        // applying specific class for the first selected operation, which has the OperationsActionsMenu displayed nearby
        if (operation.op_id === this.selectedOperations[0].op_id)
          classes.push("selected-card__first");
      }

      /**
       * for the OF view, the color category border is applied on the details column
       */
      if (!this.isList && this.schedulingCurrentColorCategory) {
        const categoryClass = getColorCategoryClass(
          operation,
          this.schedulingCurrentColorCategory,
        );
        if (categoryClass) classes.push(categoryClass);
      }

      if (!this.hoveredOperation || this.isHoveredOperation(operation))
        classes.push("font-weight-bold");
      else classes.push("faded");
      return classes;
    },
    getOperationStyle(
      operation: SchedulingOperation,
      dayIndex: number,
    ): HTMLElementStyleObject {
      const {showAll} = this;
      const {
        gr_idx, //the gantt row index this operation should be placed in, for a given sector
        delta_days = 0, //diff between max_date and min_date
        delta_weekend = 0, //number of weekend days between max_date and min_date (only has value if weekend are hidden to prevent unnecessary operations in the back)
      } = operation;
      if (!showAll && gr_idx > 0) return {display: "none"};
      const cellSpacing = "var(--gantt-operation-spacing)";
      const cellTotalPadding = "calc(var(--gantt-operation-spacing) * 2)";
      const minOperationWidth =
        this.ganttMesh === "week"
          ? `calc(var(--gantt-mesh-month-cell-width) - ${cellTotalPadding})`
          : `calc(100% - ${cellTotalPadding})`;
      const operationWidth = Math.max(delta_days - delta_weekend, 1);

      const actualOperationWidth = `calc(${
        operationWidth * 100
      }% - ${cellTotalPadding})`;
      /**
       * prevents overflowing horizontally (the duration of an operation can go beyond the current displayed period)
       */
      const maxOperationWidth = `calc(100% * ${
        this.ganttDaysArray.length - dayIndex
      } - ${cellSpacing})`;

      /**
       * special treatment for past operations when past delay is shown\
       * - the vertical positioning is based on the index in the operations array rather than the gr_idx
       * this is because the gr_idx depends on the date of the operation and the past column aggregates many dates
       * - the width is set to the minimum width to prevent overlapping with other operations
       * this is only true for operations that do not have the first
       */
      // const coeff = this.getGanttIsPastDelayShown && !dayIndex ? index : gr_idx;

      return {
        position: "absolute",
        top: `calc(${
          gr_idx
            ? // additional top padding for the first operation
              `(var(--gantt-operation-height) + ${cellSpacing})`
            : "var(--gantt-operation-height)"
        } * ${gr_idx} + ${cellSpacing})`,
        width: `min(${maxOperationWidth}, max(${minOperationWidth}, ${actualOperationWidth}))`,
      };
    },
    getOperationsPerDay(day: string, index: number): SchedulingOperation[] {
      if (!this.processedOperations.length) return [];

      const filteredOps = this.processedOperations.filter(
        (operation: SchedulingOperation) => {
          const opDate = operation.new_date || operation.day_date;
          const startDate = moment(opDate);
          if (index) return moment(day).isSame(startDate, "day");
          if (this.getGanttIsPastDelayShown)
            return moment(day).isSameOrAfter(startDate, "day");

          const {min_date, max_date} = operation;
          const minDate = moment(min_date);
          const maxDate = moment(max_date);

          return moment(day).isBetween(minDate, maxDate, "day", "[]");
        },
      );
      return this.sortOperations(filteredOps);
    },
    isHoveredOperation(operation: SchedulingOperation): boolean {
      if (!this.hoveredOperation) return false;
      return (
        getGanttOperationKey(this.hoveredOperation) ===
        getGanttOperationKey(operation)
      );
    },
    setHoveredOperation(operation: SchedulingOperation) {
      this.hoveredOperation = operation;
    },
    triggerKeyEvent(code: string) {
      if (!code) return;
      const action = code.replace("Arrow", "").toLowerCase();
      let value = 0;
      switch (action) {
        case "left":
          value = -1;
          break;
        case "right":
          value = 1;
          break;
        default:
          value = 0;
      }
      if (!value) return;
      /**
       * triggers the `moveIndividualCards` through a watcher\
       * this is done as such due to the will of being able to move
       * operations from potentially distinct entities from one entity's
       * OperationsActionsMenu
       */
      this.ganttDateUpdate = {value};
    },
    async moveIndividualCards(value = 0) {
      const {
        userData,
        selectedSimulation,
        selectedOperations,
        pg_ops,
        dailyLoadRates,
        clientParameters,
        ganttSectorCapacities,
        isList,
      } = this;
      const {has_gantt_load_adjustment} = clientParameters;

      const {client_id, id, name} = userData || {};
      const {id: simulationId} = selectedSimulation;
      // prevent moving multiple times if an operation is ongoing
      if (this.silentLoading) return;

      const uniqSelectedOperations = _.uniqBy<SchedulingOperation>(
        pg_ops.filter((o: SchedulingOperation) =>
          selectedOperations.some(
            (so: SchedulingOperation) =>
              /**
               * when we move an operation a second time, we do not update the operation in the parent
               * the first condition below ensures the operation is updated through this component's state and not the parent's
               */
              so.op_id === o.op_id &&
              ![so.new_status, so.op_status].includes(OF_STATUS.DONE),
          ),
        ),
        // when moving an operation we move all operations from the same FO
        "of_id",
      );

      if (!uniqSelectedOperations.length) return;

      let localDailyLoadRates = _.cloneDeep(dailyLoadRates);

      let ofIds = [
        ...new Set(
          (this.isList ? selectedOperations : uniqSelectedOperations).map(
            ({of_id}) => of_id,
          ),
        ),
      ];
      const dataToSave: {
        canAdjustLoadToCapa: boolean;
        data: {
          update: {
            new_date?: string;
            trigger_function?: boolean;
            first_day_duration?: number;
            event_extra_infos: LoadEventRowEventInfos;
          };
          initial: (typeof uniqSelectedOperations)[number];
        };
      }[] = [];
      const isMovingRight = Math.sign(value) === 1;
      const shouldAdjustLoadToCapa = has_gantt_load_adjustment && isMovingRight;
      for (const selectedOperation of uniqSelectedOperations) {
        const {new_date, day_date, op_dates, secteur_id, op_duration} =
          selectedOperation || {};
        const minOPDates = _.min(op_dates);
        const actualISODate = minOPDates ?? new_date ?? day_date;
        //handle horizontal move
        const actualDate = moment(actualISODate);
        const timestep = "days";
        // FIXME : this doesn't work when we move a multi day operation since this doesn't take closed days into account
        let dateIncrement = value;
        let newDate = actualDate.add(dateIncrement, timestep);
        let remaining_capa = 0;

        const opSector = this.stations.find(({id}) => id === secteur_id);
        const sectorCalendars = this.getCtxSectorOrderedCalendars(opSector);

        if (
          this.getGanttIsPastDelayShown &&
          newDate.isSameOrBefore(this.ganttPeriod[0]) &&
          isMovingRight
        )
          newDate = moment();
        else if (!isWorkDay(newDate, sectorCalendars)) {
          /**
           * this logic is similarly applied on the back (see GNT-MOV-DTS)
           */
          newDate = this.getCtxNextAvailableDate(
            newDate,
            !isMovingRight,
            opSector,
          );
        }

        let newDateISO = moment(newDate).format(DATE_DEFAULT_FORMAT);

        let update: {
          new_date?: string;
          trigger_function?: boolean;
          first_day_duration?: number;
          event_extra_infos: LoadEventRowEventInfos;
        } = {
          ...(actualISODate !== newDateISO && {
            new_date: newDateISO,
          }),
          event_extra_infos: {
            updated_by: id,
          },
        };
        const currentOpIdx = uniqSelectedOperations.findIndex(
          (op) => op.of_id === selectedOperation.of_id,
        );
        let canAdjustLoadToCapa = shouldAdjustLoadToCapa;
        if (shouldAdjustLoadToCapa) {
          if ("new_date" in update) {
            if (
              !isList &&
              Object.keys(localDailyLoadRates).includes(update.new_date)
            ) {
              for (const [date, capaAndLoadRate] of Object.entries<{
                load: number;
                capa: number;
                mappedOpsWithDailyLoad: {of_id: string; load: number}[];
              }>(localDailyLoadRates)) {
                if (date < update.new_date) continue;
                const {
                  load = 0,
                  capa = ganttSectorCapacities[secteur_id],
                  mappedOpsWithDailyLoad = [],
                } = capaAndLoadRate;
                const actualPlacedLoad =
                  load -
                  _.sumBy(
                    mappedOpsWithDailyLoad.filter(({of_id}) =>
                      ofIds.includes(of_id),
                    ),
                    ({load}) => load || 0,
                  );
                remaining_capa = Math.max(
                  +(capa - actualPlacedLoad).toFixed(2),
                  0,
                );
                if (remaining_capa > 0 && isWorkDay(date, sectorCalendars)) {
                  update.new_date = date;
                  break;
                }
                remaining_capa = 0;
              }
            }

            if (remaining_capa === 0) {
              const [, result] = await this.apiClient.getNextAvailableCapaDay({
                simulation_id: simulationId,
                of_ids: ofIds,
                sector_id: secteur_id,
                new_date: update.new_date,
                calendars: sectorCalendars,
                op_duration,
              });

              const {first_day_duration, new_date} = result;
              if (first_day_duration !== 0) {
                update = {
                  ...update,
                  first_day_duration,
                  new_date,
                };
              }
              canAdjustLoadToCapa =
                shouldAdjustLoadToCapa && first_day_duration !== 0;
            } else {
              update = {
                ...update,
                first_day_duration: Math.min(
                  op_duration,
                  +remaining_capa.toFixed(2),
                ),
              };
            }
          }

          //  update localDailyLoadRates for accurate future first_day_duration on multiselect move
          localDailyLoadRates = this.updateLocalDailyLoadRates({
            daily_load_rates: localDailyLoadRates,
            op: selectedOperation,
            future_of_ids: uniqSelectedOperations
              .slice(currentOpIdx)
              .map((op) => op.of_id),
            new_date: update.new_date,
            first_day_duration: +remaining_capa.toFixed(2),
          });

          newDateISO = moment(update.new_date).format(DATE_DEFAULT_FORMAT);
        }

        if (update.new_date && this.doesClientHaveShiftPlanning) {
          update.new_date = getNewDateFromShift(
            newDateISO,
            this.clientFirstShift,
          );
        }
        //we prepare the postgres parsing :
        const quickParse = true;
        if (quickParse) update.trigger_function = false;

        dataToSave.push({
          canAdjustLoadToCapa,
          data: {
            update,
            initial: selectedOperation,
          },
        });

        ofIds = ofIds.filter((ofId) => ofId !== selectedOperation.of_id);
      }

      // silently refreshing data
      this.silentLoading = true;
      for (const [canAdjustLoadToCapa, groupedData] of Object.entries(
        _.groupBy(dataToSave, "canAdjustLoadToCapa"),
      )) {
        const data = groupedData.map(({data}) => data);
        await this.apiClient.pgSaveSchedulingEvents({
          simulation: selectedSimulation,
          simulation_id: simulationId,
          data,
          userData: {client_id, id, name},
          unique_operations: true,
          should_handle_op_duration: true,
          has_shift_planning: this.doesClientHaveShiftPlanning,
          ...(canAdjustLoadToCapa && {
            has_gantt_load_adjustment,
          }),
        });
      }

      await this.loadCharge();

      this.$segment.value.track("[Ordo] Operation Planned Date Updated", {
        origin: "menu",
      });
    },
    keyUpEventHandler: _.debounce(function (e: KeyboardEvent) {
      this.triggerKeyEvent(e?.code);
    }, 150),
    keyDownEventHandler(e: KeyboardEvent) {
      const {code} = e;
      if (!code) return;
      const action = code.replace("Arrow", "").toLowerCase();
      if (["up", "down", "right", "left"].includes(action)) e.preventDefault();
    },
    onOperationClick(operation: SchedulingOperation) {
      this.$emit("operation-clicked", operation);
    },
    onShowAllClick() {
      this.showAll = !this.showAll;
      if (!this.showAll) this.$emit("operation-clicked", null);
    },
    onClickOutsideCard(event, operation: SchedulingOperation): void {
      if (!isSelectedOperation(this.selectedOperations, operation)) return;
      return onClickOutsideSchedulingOperation(event)
        ? this.$emit("operation-clicked", null)
        : null;
    },
    getDisplayedOperations(
      operations: SchedulingOperation[],
    ): SchedulingOperation[] {
      if (this.areThereHiddenOps) {
        return operations
          .filter((op: SchedulingOperation) => op.gr_idx === 0)
          .slice(0, 1);
      }
      return operations;
    },
    getActionsMenuPositionalProps(index: number) {
      const displaySide =
        index > this.ganttDaysArray.length / 2 ? "left" : "right";
      return {
        displaySide,
        [displaySide]: "-8px",
      };
    },
    getArrowsState(dayIndex: number) {
      return {
        Left: dayIndex || !this.getGanttIsPastDelayShown,
        Right: true,
      };
    },
    handleShowAllDisplay(payload: Record<string, string>) {
      if (!payload.new_secteur_id) return;
      // show all for new sector
      if (
        payload.new_secteur_id === this.entity[getGanttInterestKey(this.isList)]
      )
        this.showAll = true;
      // hide operations for previous sector
      else if (
        payload.secteur_id === this.entity[getGanttInterestKey(this.isList)]
      )
        this.showAll = false;
    },
    isFirstSelectedOperation(operation: SchedulingOperation): boolean {
      return (
        isSelectedOperation(this.selectedOperations, operation) &&
        getGanttOperationKey(operation) ===
          getGanttOperationKey(this.selectedOperations[0])
      );
    },
    getGanttOperationKey,
    updateLocalDailyLoadRates({
      daily_load_rates,
      op,
      future_of_ids,
      new_date,
      first_day_duration,
    }: {
      daily_load_rates: Record<
        string,
        {
          value: string | number;
          load: number;
          capa: number;
          unit: string;
          loadDetail: {of_id: string; load: number}[];
          mappedOpsWithDailyLoad: {
            of_id: string;
            load: number;
          }[];
        }
      >;
      op: SchedulingOperation;
      future_of_ids: string[];
      new_date: string;
      first_day_duration: number;
    }) {
      const {ganttSectorCapacities} = this;
      const dailyLoadRatesCopy = structuredClone(daily_load_rates);
      const {of_id, day_date, op_duration, secteur_id} = op;
      const opSector = this.stations.find(({id}) => id === secteur_id);
      const sectorCalendars = this.getCtxSectorOrderedCalendars(opSector);

      Object.keys(dailyLoadRatesCopy).forEach((date) => {
        if (date < day_date) return;

        dailyLoadRatesCopy[date].mappedOpsWithDailyLoad = dailyLoadRatesCopy[
          date
        ].mappedOpsWithDailyLoad.filter(
          ({of_id: id}) => !future_of_ids.includes(id),
        );

        dailyLoadRatesCopy[date].load = _.sumBy(
          dailyLoadRatesCopy[date].mappedOpsWithDailyLoad,
          "load",
        );
      });

      let hasInsertedFirstDay = false;
      let remaining_load = op_duration;

      for (const [date, capaAndLoadRate] of Object.entries(
        dailyLoadRatesCopy,
      )) {
        if (date < new_date || !isWorkDay(date, sectorCalendars)) continue;

        const {capa = ganttSectorCapacities[secteur_id]} = capaAndLoadRate;

        const loadToInsert = hasInsertedFirstDay
          ? Math.min(remaining_load, capa)
          : first_day_duration;

        dailyLoadRatesCopy[date].mappedOpsWithDailyLoad.push({
          of_id,
          load: loadToInsert,
        });

        dailyLoadRatesCopy[date].load += loadToInsert;
        remaining_load -= loadToInsert;
        if (!remaining_load) break;

        if (!hasInsertedFirstDay) hasInsertedFirstDay = !hasInsertedFirstDay;
      }

      return dailyLoadRatesCopy;
    },
  },
});
</script>

<style lang="scss">
// this is at the #app level
.gantt-row--cell-tooltip {
  padding: 0 !important;

  & .operation-card {
    padding: 0 !important;
  }

  &.mesh-week .gantt-header--wrapper {
    top: 0;
  }
}
</style>

<style scoped lang="scss">
/** TODO: scoped */
@import "@/scss/constants.scss";

.gantt-row--wrapper {
  display: grid;

  &:nth-child(even) .gantt-row {
    background: rgb(var(--v-theme-newSubBackground));
  }

  &:nth-child(odd) .gantt-row {
    background: rgb(var(--v-theme-newLayerBackground));
  }

  & .gantt-row {
    display: inline-flex;
    width: fit-content;

    & .gantt-row--cell-wrapper {
      display: flex;
      border-bottom: var(--gantt-border);
      position: relative;

      & .gantt-row--cell {
        padding: var(--gantt-operation-spacing);
        position: relative;
        display: flex;
        flex-direction: column;
        gap: var(--gantt-operation-spacing);

        & .gantt-row--cell-operation {
          height: var(--gantt-operation-height);
          display: flex;
          flex-direction: column;
          gap: 8px;
          border: 1px solid rgb(var(--v-theme-newSelected));
          border-radius: 8px;
          background: rgb(var(--v-theme-newLayerBackground));
          padding: 8px $ganttRowCellOperationXPadding;
          cursor: pointer;
          transition: opacity 0.25s;
          z-index: 2;

          & .gantt-row--cell-operation-expand {
            cursor: pointer;
            position: absolute;
            bottom: 4px;
            right: 4px;
          }

          &.is-synthetic {
            padding: calc($ganttRowCellOperationXPadding / 2);

            & .gantt-row--cell-operation-expand {
              bottom: 0;
              right: 0;
            }
          }

          &.selected-card {
            border-width: 2px;
            // outline: 2px solid rgb(var(--v-theme-newPrimaryRegular));
            border-color: rgb(var(--v-theme-newPrimaryRegular));
            background-color: rgb(var(--v-theme-newHover));
            // for the actions menu
            z-index: 2;
            &.selected-card__first {
              z-index: 3;
            }
          }

          &.faded {
            opacity: 0.33;
          }

          &[class*="--colors-categories"]::before {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          & .gantt-row--cell-operation-container {
            height: 100%;

            & .gantt-row--cell-operation-information {
              height: 100%;
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: space-around;

              & .gantt-row--cell-operation-information-title {
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: 4px;
                color: rgb(var(--v-theme-newMainText));

                & strong,
                & span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 1.1;
                }

                & .gantt-row--cell-operation-header {
                  display: flex;
                  gap: 8px;
                }
              }
            }

            &.is-synthetic {
              overflow: hidden;
              & .gantt-row--cell-operation-information {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;
                & .gantt-row--cell-operation-information-title {
                  font-size: 14px;
                  flex: 1 1 0;
                  min-width: calc(
                    var(--gantt-mesh-month-cell-width) -
                      var(--gantt-operation-spacing) * 2 -
                      $ganttRowCellOperationXPadding * 2
                  );
                  max-width: max-content;
                  flex-direction: row;
                }
              }
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: -1px;
          top: 0px;
          width: 1px;
          height: 100%;
          border-right: 1px solid rgb(var(--v-theme-newHover));
          z-index: 1;
        }

        &:last-child::after {
          border-right: var(--gantt-border);
          right: 0px;
        }

        &:deep(.v-skeleton-loader) > div {
          height: var(--gantt-operation-height);
          border-radius: 8px;
          width: 100%;
        }
      }

      & .gantt-row--cell-unfolding-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 4px;
        z-index: 1;
        &.position-to-right {
          right: 0;
        }
        &.position-to-left {
          left: 0;
        }
      }
    }
  }

  & .see-more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 6px 12px;
  }
}

.v-lazy:last-child .gantt-row--wrapper {
  & .gantt-row-prefix,
  & .gantt-row-prefix--entity-name-wrapper {
    border-bottom-left-radius: 8px;
  }
}

.operation-status {
  pointer-events: none;
}
</style>
