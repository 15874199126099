<template>
  <div
    :class="[
      {'text-only': textOnly},
      {'is-full-week': isFullWeek},
      'capacity-day-cell',
    ]"
  >
    <v-skeleton-loader :class="{loading}" :loading="loading" type="text">
      <span v-if="textOnly" :class="getCapaClassTextOnly">
        <CapaTooltip
          :capa-percentage="capaPercentage"
          :computed-charge="computedCharge"
          :computed-capa="computedCapa"
          :is-past="isPast"
          :unit="sectorTree.unite || sectorTree.unit"
          :text-prefix="textPrefix"
          :text-suffix="suffix"
          text-only
        />
      </span>
      <div v-else :class="getCapaClass">
        <CapaTooltip
          :capa-percentage="capaPercentage"
          :computed-charge="computedCharge"
          :computed-capa="computedCapa"
          :unit="sectorTree.unite || sectorTree.unit"
          :is-past="isPast"
          :text-prefix="textPrefix"
          :text-suffix="suffix"
        />
      </div>
    </v-skeleton-loader>
  </div>
</template>
<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  MaybeRef,
  PropType,
  ref,
} from "vue";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";
import _ from "lodash";
import numeral from "numeral";
import moment from "moment";
import {useSectorTree} from "@/composables/useSectorTree";
import CapaTooltip from "./CapaTooltip.vue";
import {SectorLike, ShiftPlanningItem} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "@/stores/mainStore";
import {unref} from "vue";
import {
  getDisplayedPeriod,
  isPastDate,
  DATE_DEFAULT_FORMAT,
  TIME_DEFAULT_FORMAT,
  DATE_WITH_TIME_FORMAT,
} from "@oplit/shared-module";

numeral.locale("fr");

export default defineComponent({
  name: "capacity-day-cell",
  components: {CapaTooltip},
  props: {
    mc: {
      type: Object as PropType<SectorLike>,
      default: () => ({} as SectorLike),
    },
    day: {type: String, default: ""},
    isFullWeek: {type: Boolean, default: false},
    isPast: {type: Boolean, default: false},
    textOnly: {type: Boolean, default: false},
    periodData: {type: Object, default: () => ({})},
    dayEnd: {type: String, default: ""},
    shift: {
      type: Object as PropType<Partial<ShiftPlanningItem>>,
      default: () => null,
    },
    showPrefix: {type: Boolean, default: false},
    selectedView: {
      type: String as PropType<"day_view" | "week_view" | "month_view">,
    },
    loading: {type: Boolean},
    suffix: {type: String, default: ""},
  },
  setup(props) {
    const {locale} = useI18n();
    const {
      selectedSimulation: simulation,
      schedulingMachineCentersAndTags,
      totalCapaBySector,
      totalLoadBySectorWithSchedulingChanges,
    } = storeToRefs(useSchedulingStore());

    const {sectorTree} = useSectorTree(
      props.mc,
      schedulingMachineCentersAndTags,
    );
    const parsedPeriod = computed(() => {
      const startDate = props.day || props.periodData.startDate;
      const endDate = props.dayEnd || props.day || props.periodData.endDate;

      return {startDate, endDate};
    });

    const mainStore = useMainStore();
    const {perimeters, userData, apiClient, isDevEnv, variables} =
      storeToRefs(mainStore);

    const shiftPlanning = inject<MaybeRef<Partial<ShiftPlanningItem[]>>>(
      "shiftPlanning",
      [],
    );
    const hasShiftPlanning = inject<ComputedRef<boolean>>("hasShiftPlanning");
    const isUsingShiftPlanning = computed(
      () => hasShiftPlanning.value && !!props.shift,
    );
    const sumOfAllShiftsCapa = computed<number>(() =>
      unref(isUsingShiftPlanning)
        ? unref(shiftPlanning).reduce(
            (sum, shift) => sum + shift.values.at(moment(props.day).day() - 1),
            0,
          )
        : 0,
    );
    const textPrefix = computed(() => {
      if (!props.showPrefix) return;
      const prefix = props.shift?.name
        ? props.shift.name
        : getDisplayedPeriod(
            props.selectedView === "month_view"
              ? moment(props.periodData.startDate).endOf("week")
              : props.periodData.startDate,
            props.selectedView === "month_view",
            locale.value,
          );
      return `${_.upperFirst(prefix)} -`;
    });

    const pg_capa = computed(() => {
      if (!totalCapaBySector.value[props.mc.secteur_id]) return [];

      const dateCondition = getFilterCondition(parsedPeriod.value);

      return totalCapaBySector.value[props.mc.secteur_id].filter(dateCondition);
    });
    const computedCharge = computed(() => {
      if (!totalLoadBySectorWithSchedulingChanges.value[props.mc.secteur_id])
        return 0;

      const startDate = addShiftTimeToDate(
        parsedPeriod.value.startDate,
        props.shift,
      );
      const endDate = addShiftTimeToDate(
        parsedPeriod.value.endDate,
        props.shift,
        true,
      );

      const dateCondition = getFilterCondition({startDate, endDate});

      const periodLoad = totalLoadBySectorWithSchedulingChanges.value[
        props.mc.secteur_id
      ]
        .map(({day_date, ...rest}) => ({
          ...rest,
          day_date: addShiftTimeToDate(day_date, unref(shiftPlanning)[0]),
        }))
        .filter(dateCondition);

      return _.sumBy(periodLoad, "load");
    });

    function getFilterCondition(period: {startDate: string; endDate: string}) {
      const {startDate, endDate} = period;

      if (!props.isPast)
        return ({day_date}) => day_date >= startDate && day_date <= endDate;

      const currentShiftIndex = unref(shiftPlanning).findIndex(
        ({start_time}) => start_time === props.shift.start_time,
      );

      return ({day_date}) => {
        if (day_date >= startDate) return false;
        if (currentShiftIndex === -1) return true;

        /**
         * either the current item has time defined or we set it to
         * the first `shiftPlanning.start_time`
         */
        const dayDateWithShift = addShiftTimeToDate(
          day_date,
          unref(shiftPlanning)[0],
        );
        const currentTime =
          moment(dayDateWithShift).format(TIME_DEFAULT_FORMAT);
        /**
         * time has to be within current and next shifts' `start_time`
         */
        if (currentTime < unref(shiftPlanning)[currentShiftIndex].start_time)
          return false;
        if (currentShiftIndex + 1 === unref(shiftPlanning).length) return true;
        return (
          currentTime < unref(shiftPlanning)[currentShiftIndex + 1].start_time
        );
      };
    }
    /**
     * adds HH:mm to date depending on the @shift passed
     * will return @date if `props.shift.start_time` is not specified
     * or if @date already has time
     */
    function addShiftTimeToDate(
      date: string,
      shift: Partial<ShiftPlanningItem>,
      isEndDate = false,
    ) {
      if (date.length !== DATE_DEFAULT_FORMAT.length) return date;
      if (!shift?.start_time) {
        /**
         * when moving operations in the day view we add the time suffix to their `day_date`\
         * for proper calculations when viewing in a view different than the day view we need to include day boudaries
         */
        const timeSuffix = isEndDate ? "23:59:59.999" : "00:00:00.000";
        return `${date} ${timeSuffix}`;
      }
      const {start_time} = shift;
      return moment(`${date} ${start_time}`).format(DATE_WITH_TIME_FORMAT);
    }

    return {
      pg_capa,
      pg_charge: ref<unknown[]>([]),
      parsedPeriod,
      sectorTree,
      simulation,
      perimeters,
      userData,
      apiClient,
      isDevEnv,
      variables,
      schedulingMachineCentersAndTags,
      shiftPlanning,
      isUsingShiftPlanning,
      sumOfAllShiftsCapa,
      textPrefix,
      totalCapaBySector,
      computedCharge,
    };
  },
  computed: {
    daysOpen() {
      const {pg_capa} = this;
      return pg_capa.length;
    },
    computedCapa() {
      const {pg_capa, shift, day, sumOfAllShiftsCapa, isUsingShiftPlanning} =
        this;

      if (!pg_capa?.length) return 0;
      const result = _.sum(pg_capa.map((x: any) => +x.daily_capa)) || 0;

      let ratio = 1;
      if (isUsingShiftPlanning) {
        ratio =
          shift.values.at(moment(day).day() - 1) / (sumOfAllShiftsCapa || 1);
      }

      return ratio * result;
    },
    capaPercentage() {
      const {
        computedCapa: capacity,
        computedCharge: load,
        isPast,
        daysOpen,
        dayEnd: endDate,
      } = this;

      // fix day to endDate so it display properly for week/month view
      const day = endDate;

      if (isPast) {
        if (+load === 0) return this.$t("global.no_delay");
        const delay = (load * daysOpen) / (capacity || 1) || 0;

        const formatedDelay = numeral(delay).format("0.[00]");
        return `${formatedDelay} ${this.$t("global.delay_days", delay)}`;
      }

      if (isPastDate(day)) return this.$t("global.past");

      if (!daysOpen) return this.$t("global.closed");
      if (capacity === 0 && load === 0) return "0 %";
      if (capacity === 0) return this.$t("global.infinity");
      const percentage = ((load / capacity) * 100).toFixed(0);
      return percentage + " %";
    },

    getCapaClass() {
      const {
        computedCharge: load,
        computedCapa: capacity,
        isFullWeek,
        isPast,
        daysOpen,
        day,
      } = this;
      let classes = ["sheduling-row-percent"];
      if (isPast) return classes;
      if (isFullWeek)
        classes = ["fbody-2", "semi-bold", "sheduling-row-tx-charge"];
      const overcapacity = "over-capacity";
      if (!daysOpen && !isPastDate(day)) classes.push("bg-newLightGrey");
      if (+capacity === 0) {
        if (load) return [...classes, overcapacity];
        return classes;
      }
      const percentage = ((load || 0) / capacity) * 100;
      if (percentage > 100) return [...classes, overcapacity];
      return classes;
    },

    getCapaClassTextOnly() {
      const {computedCharge: load, computedCapa: capacity} = this;
      let result = "";
      const overcapacity = "text-newPinkDark1";
      if (+capacity === 0 && load) return overcapacity;
      if (+capacity === 0) return "";
      const percentage = ((load || 0) / capacity) * 100;
      if (percentage > 100) result = overcapacity;
      return result;
    },
  },
});
</script>
<style scoped lang="scss">
@import "@/scss/constants";

.sheduling-row {
  &-percent {
    min-width: 237px;
    text-align: center;
    border-bottom: 1px solid rgb(var(--v-theme-newSelected));
    background: rgb(var(--v-theme-newLayerBackground));
    position: sticky;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-tx-charge {
    width: calc(100% + 1px);
    margin-left: -1px;
    text-align: center;
    border-bottom: 1px solid rgb(var(--v-theme-newSelected));
    border-left: 1px solid rgb(var(--v-theme-newSelected));
  }
}
.text-only {
  display: inline;
}
.over-capacity {
  background: rgb(var(--v-theme-newPinkLight2));
  color: rgb(var(--v-theme-newPinkDark1));
  font-weight: bold;
}
.capacity-day-cell {
  position: sticky;
  top: $schedulingCalendarHeaderCellHeight;
  background: inherit;
  z-index: 5;

  &:not(.text-only) {
    border-top: 1px solid rgb(var(--v-theme-newSelected));
    margin-top: -1px;

    &:first-child {
      border-top-color: rgb(var(--v-theme-newMainText));
    }
  }

  &.is-full-week {
    margin-top: -1px;
  }

  &:deep(.v-skeleton-loader) {
    border-radius: 0;
    &.loading {
      border-bottom: 1px solid rgb(var(--v-theme-newSelected));
    }
  }
  &:deep(.v-skeleton-loader__text) {
    margin: 4px auto 5px;
    width: 50%;
  }
}
</style>
